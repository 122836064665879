import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../utils/status';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteAbout, fetchAbout, updateAbout } from './../../redux/aboutSlice';

const HakkindaList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingHakkinda, setEditingHakkinda] = useState(null);
    const [editedHakkinda, setEditedHakkinda] = useState({});

    const { aboutdata, aboutstatus, abouterror } = useSelector((state) => state.about);

    useEffect(() => {
        if (aboutstatus === STATUS.IDLE) {
            dispatch(fetchAbout());
        }
    }, [aboutstatus, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteAbout(id));
    };

    const handleUpdate = (id) => {
        dispatch(updateAbout({ id, updatedData: editedHakkinda }));
        setEditingHakkinda(null);
        setEditedHakkinda({});
    };

    const handleEdit = (user) => {
        setEditingHakkinda(user.id);
        setEditedHakkinda(user);
    };

    const handleCancel = () => {
        setEditingHakkinda(null);
        setEditedHakkinda({});
    };

    const handleImageChange = (index, value) => {
        const updatedImages = editedHakkinda.images.map((img, i) =>
            i === index ? { ...img, url: value } : img
        );
        setEditedHakkinda({ ...editedHakkinda, images: updatedImages });
    };

    const handleAddImage = () => {
        setEditedHakkinda({
            ...editedHakkinda,
            images: [...editedHakkinda.images, { url: '' }]
        });
    };

    const handleRemoveImage = (index) => {
        const updatedImages = editedHakkinda.images.filter((img, i) => i !== index);
        setEditedHakkinda({ ...editedHakkinda, images: updatedImages });
    };

    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-about" className="mb-2 inline-block">
                <button className="btn btn-primary">New Hakkında</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Images</TableCell>
                            <TableCell className="tableCell">Firma Sahibi</TableCell>
                            <TableCell className="tableCell">Title</TableCell>
                            <TableCell className="tableCell">Tanıtım</TableCell>
                            <TableCell className="tableCell">Tanıtım 1</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aboutstatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {aboutstatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Error: {abouterror}</TableCell>
                            </TableRow>
                        )}
                        {aboutstatus === STATUS.SUCCESS && aboutdata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingHakkinda === row.id ? (
                                        <>
                                            {editedHakkinda.images.map((image, index) => (
                                                <div key={index} className="flex items-center mb-2">
                                                    <input
                                                        type="text"
                                                        value={image.url}
                                                        onChange={(e) => handleImageChange(index, e.target.value)}
                                                        placeholder="Edit Image URL"
                                                        className="border p-1 rounded mr-2"
                                                    />
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveImage(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                className="btn btn-secondary"
                                                onClick={handleAddImage}
                                            >
                                                Add Image
                                            </button>
                                        </>
                                    ) : (
                                        <div className="flex items-center flex-wrap">
                                            {row.images.map((img, index) => (
                                                <img key={index} src={img.url} alt="" className="w-10 h-10 rounded-full mr-1 object-cover" />
                                            ))}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingHakkinda === row.id ? (
                                        <input
                                            type="text"
                                            value={editedHakkinda.firmasahibi}
                                            onChange={(e) => setEditedHakkinda({ ...editedHakkinda, firmasahibi: e.target.value })}
                                            placeholder="Edit Firma Sahibi"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.firmasahibi
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingHakkinda === row.id ? (
                                        <input
                                            type="text"
                                            value={editedHakkinda.title}
                                            onChange={(e) => setEditedHakkinda({ ...editedHakkinda, title: e.target.value })}
                                            placeholder="Edit Firma Adı"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.title
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingHakkinda === row.id ? (
                                        <input
                                            type="text"
                                            value={editedHakkinda.tanitim ? editedHakkinda.tanitim.join(", ") : ""}
                                            onChange={(e) => setEditedHakkinda({ ...editedHakkinda, tanitim: e.target.value.split(",").map(item => item.trim()) })}
                                            placeholder="Edit Tanıtım"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.tanitim ? row.tanitim.join(", ") : ""
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingHakkinda === row.id ? (
                                        <input
                                            type="text"
                                            value={editedHakkinda.tanitim1}
                                            onChange={(e) => setEditedHakkinda({ ...editedHakkinda, tanitim1: e.target.value.split(",").map(item => item.trim()) })}
                                            placeholder="Edit Tanıtım 1"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.tanitim1
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingHakkinda === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default HakkindaList;
