// src/features/dataSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

// Helper function to convert Firestore Timestamp to Date
const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp.toDate().toISOString(),
    };
};

const initialState = {
    sliderdata: [],
    sliderstatus: STATUS.IDLE,
    slidererror: null,
};

// Firestore'dan kullanıcıları almak için bir async thunk
export const fetchSlider = createAsyncThunk('slider/fetchSlider', async () => {
    const querySnapshot = await getDocs(collection(db, 'slider'));
    let slider = [];
    querySnapshot.forEach((doc) => {
        slider.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return slider;
});


// Yeni bir kullanıcı oluşturmak için bir async thunk
export const addSlider = createAsyncThunk('slider/addUsers', async ({ newUser }) => {
    const userWithTimestamp = { ...newUser, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, 'slider'), userWithTimestamp);
    const docSnapshot = await getDoc(docRef); // Change here
    return { id: docRef.id, ...convertTimestampToDate(docSnapshot.data()) };
});

// Kullanıcıyı güncellemek için bir async thunk
export const updateSlider = createAsyncThunk('slider/updateUsers', async ({ id, updatedData }) => {
    const userRef = doc(db, 'slider', id);
    await updateDoc(userRef, { ...updatedData, timeStamp: serverTimestamp() });
    const updatedSnapshot = await getDoc(userRef); // Change here
    return { id, ...convertTimestampToDate(updatedSnapshot.data()) };
});

// Kullanıcıyı silmek için bir async thunk
export const deleteSlider = createAsyncThunk('slider/deleteSlider', async (id) => {
    await deleteDoc(doc(db, 'slider', id));
    return id;
});


const sliderSlice = createSlice({
    name: 'slider',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlider.pending, (state) => {
                state.sliderstatus = STATUS.LOADING;
            })
            .addCase(fetchSlider.fulfilled, (state, action) => {
                state.sliderstatus = STATUS.SUCCESS;
                state.sliderdata = action.payload;
            })
            .addCase(fetchSlider.rejected, (state, action) => {
                state.sliderstatus = STATUS.FAIL;
                state.slidererror = action.error.message;
            })
            .addCase(addSlider.pending, (state) => {
                state.sliderstatus = STATUS.LOADING;
            })
            .addCase(addSlider.fulfilled, (state, action) => {
                state.sliderstatus = STATUS.SUCCESS;
                state.sliderdata.push(action.payload);
            })
            .addCase(addSlider.rejected, (state, action) => {
                state.sliderstatus = STATUS.FAIL;
                state.slidererror = action.error.message;
            })
            .addCase(updateSlider.pending, (state) => {
                state.sliderstatus = STATUS.LOADING;
            })
            .addCase(updateSlider.fulfilled, (state, action) => {
                state.sliderstatus = STATUS.SUCCESS;
                const index = state.sliderdata.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.sliderdata[index] = action.payload;
                }
            })
            .addCase(updateSlider.rejected, (state, action) => {
                state.sliderstatus = STATUS.FAIL;
                state.slidererror = action.error.message;
            })
            .addCase(deleteSlider.pending, (state) => {
                state.sliderstatus = STATUS.LOADING;
            })
            .addCase(deleteSlider.fulfilled, (state, action) => {
                state.sliderstatus = STATUS.SUCCESS;
                state.sliderdata = state.sliderdata.filter((user) => user.id !== action.payload);
            })
            .addCase(deleteSlider.rejected, (state, action) => {
                state.sliderstatus = STATUS.FAIL;
                state.slidererror = action.error.message;
            });
    },
});

export default sliderSlice.reducer;
