import { configureStore } from '@reduxjs/toolkit'
import referansSlice from './referansSlice'
import konumSlice from './konumSlice'
import iletisimSlice from './iletisimSlice'
import aboutSlice from './aboutSlice'
import corporateSlice from './corporateSlice'
import projectSlice from './projectSlice'
import usersSlice from './usersSlice';
import sliderSlice from './sliderSlice';
import adminSlice from './adminSlice'
import themeSlice from './themeSlice.js'


export const store = configureStore({
    reducer: {
        referans: referansSlice,
        konum: konumSlice,
        communication: iletisimSlice,
        about: aboutSlice,
        corporate: corporateSlice,
        project: projectSlice,
        users: usersSlice,
        slider: sliderSlice,
        admin: adminSlice,
        theme: themeSlice,
    },
})