import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUS } from "../../utils/status";
import { fetchProjects, deleteProject, updateProject } from "../../redux/projectSlice";
import { serverTimestamp } from "firebase/firestore";

const ProjectList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingProject, setEditingProject] = useState(null);
    const [editedProject, setEditedProject] = useState({});

    const { projectdata, projectstatus, projecterror } = useSelector((state) => state.project);

    useEffect(() => {
        if (projectstatus === STATUS.IDLE) {
            dispatch(fetchProjects());
        }
    }, [projectstatus, dispatch]);


    const handleDelete = (id) => {
        dispatch(deleteProject(id));
    };

    const handleUpdate = (id) => {

        dispatch(updateProject({ id, updatedData: editedProject, timeStamp: serverTimestamp() }));
        setEditingProject(null);
        setEditedProject({});
    };

    const handleEdit = (project) => {
        setEditingProject(project.id);
        setEditedProject(project);
    };

    const handleCancel = () => {
        setEditingProject(null);
        setEditedProject({});
    };
    const handleImageChange = (index, value) => {
        const updatedImages = editedProject.images.map((img, i) =>
            i === index ? { ...img, url: value } : img
        );
        setEditedProject({ ...editedProject, images: updatedImages });
    };

    const handleAddImage = () => {
        setEditedProject({
            ...editedProject,
            images: [...editedProject.images, { url: '' }]
        });
    };
    const handleRemoveImage = (index) => {
        const updatedImages = editedProject.images.filter((img, i) => i !== index);
        setEditedProject({ ...editedProject, images: updatedImages });
    };

    const statusClasses = {
        "Tamamlandı": "bg-green-100 text-green-800",
        "Devam Ediyor": "bg-gray-100 text-gray-800",
    };

    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-project" className="mb-2 inline-block">
                <button className="btn btn-primary">New Project</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">Images</TableCell>
                            <TableCell className="tableCell">Title</TableCell>
                            <TableCell className="tableCell">Company</TableCell>
                            <TableCell className="tableCell">İs Veren</TableCell>
                            <TableCell className="tableCell">Kapasite</TableCell>
                            <TableCell className="tableCell">Description</TableCell>
                            <TableCell className="tableCell">Yıl</TableCell>
                            <TableCell className="tableCell">Şehir</TableCell>
                            <TableCell className="tableCell">Grup</TableCell>
                            <TableCell className="tableCell">Status</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectstatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={7} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {projectstatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={7} className="tableCell">Error: {projecterror}</TableCell>
                            </TableRow>
                        )}
                        {projectstatus === STATUS.SUCCESS && projectdata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <>
                                            {editedProject.images.map((image, index) => (
                                                <div key={index} className="flex items-center mb-2">
                                                    <input
                                                        type="text"
                                                        value={image.url}
                                                        onChange={(e) => handleImageChange(index, e.target.value)}
                                                        placeholder="Edit Image URL"
                                                        className="border p-1 rounded mr-2"
                                                    />
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveImage(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                className="btn btn-secondary"
                                                onClick={handleAddImage}
                                            >
                                                Add Image
                                            </button>
                                        </>
                                    ) : (
                                        <div className="flex items-center flex-wrap">
                                            {row.images.map((img, index) => (
                                                <img key={index} src={img.url} alt="" className="w-10 h-10 rounded-full mr-1 object-cover" />
                                            ))}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.title}
                                            onChange={(e) => setEditedProject({ ...editedProject, title: e.target.value })}
                                            placeholder="Edit Firma Sahibi"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.title
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.company}
                                            onChange={(e) => setEditedProject({ ...editedProject, company: e.target.value })}
                                            placeholder="Edit Firma Sahibi"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.company
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.isveren}
                                            onChange={(e) => setEditedProject({ ...editedProject, isveren: e.target.value })}
                                            placeholder="Edit Firma Sahibi"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.isveren
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.kapasite}
                                            onChange={(e) => setEditedProject({ ...editedProject, kapasite: e.target.value })}
                                            placeholder="Edit Kapasite"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.kapasite
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.description}
                                            onChange={(e) => setEditedProject({ ...editedProject, description: e.target.value })}
                                            placeholder="Edit Description"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.description.slice(0, 10) + "..."
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.year}
                                            onChange={(e) => setEditedProject({ ...editedProject, year: e.target.value })}
                                            placeholder="Edit Year"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.year
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.sehir}
                                            onChange={(e) => setEditedProject({ ...editedProject, sehir: e.target.value })}
                                            placeholder="Edit City"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.sehir
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.grup}
                                            onChange={(e) => setEditedProject({ ...editedProject, grup: e.target.value })}
                                            placeholder="Edit Grup"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        <span className={`py-1 px-2 rounded ${statusClasses[row.status] || 'bg-gray-100 text-gray-800'}`}>
                                            {row.grup.charAt(0).toUpperCase() + row.grup.slice(1).toLowerCase() + " Projeler"}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingProject === row.id ? (
                                        <input
                                            type="text"
                                            value={editedProject.status}
                                            onChange={(e) => setEditedProject({ ...editedProject, status: e.target.value })}
                                            placeholder="Edit Status"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        <span className={`py-1 px-2 rounded ${statusClasses[row.status] || 'bg-gray-100 text-gray-800'}`}>
                                            {row.status}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingProject === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton1 p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ProjectList;
