import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/adminSlice";

const Navbar = () => {
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.admin);

  useEffect(() => {
    if (user) {
      setUsers(user);
    } else {
      setTimeout(() => {
        window.location = "/login";
      }, 3000);
    }
  }, [user]);

  return (
    <div className="h-20 border-b border-gray-300 flex items-center text-sm text-gray-700">
      <div className="w-full px-5 flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={users?.photoURL ? users?.photoURL : "https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
            alt="User Avatar"
            className="w-10 h-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14 rounded-full mr-4"
          />
          <div className="hidden lg:flex text-lg lg:text-xl font-bold mr-4">
            {users?.displayName ? users?.displayName : users?.email} hoş geldiniz.
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center mr-6 font-bold">
            <LanguageOutlinedIcon className="text-xl sm:text-2xl mr-1" />
            Türkçe
          </div>
          <div className="flex items-center mr-6 font-bold">
            <DarkModeOutlinedIcon className="text-xl sm:text-2xl" />
          </div>
          <div className="flex items-center mr-6 font-bold" onClick={() => dispatch(logout())}>
            <ExitToAppIcon className="text-2xl sm:text-4xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
