import { Splide, SplideSlide } from '@splidejs/react-splide';
import Loader from './../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { STATUS } from './../utils/status';
import { fetchAbout } from '../redux/aboutSlice';
import { toast } from 'react-toastify';

const About = () => {
    const dispatch = useDispatch();
    const { aboutdata, aboutstatus, abouterror } = useSelector((state) => state.about);

    useEffect(() => {
        if (aboutstatus === STATUS.IDLE) {
            dispatch(fetchAbout());
        }
    }, [aboutstatus, dispatch]);

    // Yüklenme durumunu kontrol etme
    if (aboutstatus === STATUS.LOADING) {
        return <div className="h-[40vh] flex items-center">  <Loader />    </div>
    }

    // Hata durumunu kontrol etme
    if (aboutstatus === STATUS.FAIL) {
        return <div className="text-center mt-4">Error: {toast.error(abouterror)}</div>;
    }

    // Veri boşsa kontrol etme
    if (!aboutdata || aboutdata.length === 0) {
        return <div className="text-center mt-4">No location data available</div>;
    }

    // Konum verisi mevcutsa bileşeni render etme
    const { firmasahibi, images, tanitim, tanitim1, title } = aboutdata[0];
    return (
        <div className="bg-gray-100 py-2">
            <div className="container">
                <Splide
                    options={{
                        rewind: true,
                        perPage: 1,
                        withRatio: '100vh', // Resimlerin yatay oranı
                        heightRatio: '50vh', // Ekran yüksekliğinin yarısı kadar

                    }}
                    aria-label="Projeler"
                >
                    {!aboutdata?.length && (
                        <div className="h-[40vh] flex items-center"> <Loader /> </div>
                    )}
                    {images.map((data, i) => (
                        <SplideSlide key={i}>
                            <img
                                className="h-[40v] lg:h-[60vh] w-full rounded"
                                src={data.url}
                                alt={i}
                            />
                        </SplideSlide>
                    ))}
                </Splide>

                <div className="mx-auto bg-white p-8 shadow-md rounded-md">
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-4">{title} Hakkında</h1>
                    <ul className="list-disc mb-4 pl-4">
                        <li className="text-lg text-black-700">
                            <span className='font-bold'> {firmasahibi}</span> {tanitim1}
                        </li>
                        {tanitim.map((data, index) => (<li key={index} className="text-lg mb-2 text-black-700">{data}</li>))}

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default About;
