// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBFYfw93SPq8F9JAbMg3SljisuubSS8qQc",
    authDomain: "gurgenelektrik-5b104.firebaseapp.com",
    projectId: "gurgenelektrik-5b104",
    storageBucket: "gurgenelektrik-5b104.appspot.com",
    messagingSenderId: "202983952415",
    appId: "1:202983952415:web:93d41ed391863b60c7052a"
};

// Initialize Firebase
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const provider = new GoogleAuthProvider();
