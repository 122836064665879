import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../utils/status';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteReferanslar, fetchReferanslar, updateReferanslar } from './../../redux/referansSlice';

const ReferanslarList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingReferanslar, setEditingReferanslar] = useState(null);
    const [editedReferanslar, setEditedReferanslar] = useState({});

    const { referanslardata, referanslarstatus, referanslarerror } = useSelector((state) => state.referans);

    useEffect(() => {
        if (referanslarstatus === STATUS.IDLE) {
            dispatch(fetchReferanslar());
        }
    }, [referanslarstatus, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteReferanslar(id));
    };

    const handleUpdate = (id) => {
        dispatch(updateReferanslar({ id, updatedData: editedReferanslar }));
        setEditingReferanslar(null);
        setEditedReferanslar({});
    };

    const handleEdit = (user) => {
        setEditingReferanslar(user.id);
        setEditedReferanslar(user);
    };

    const handleCancel = () => {
        setEditingReferanslar(null);
        setEditedReferanslar({});
    };

    const handleImageChange = (e) => {
        const updatedImages = [...editedReferanslar.images];
        updatedImages[0] = { ...updatedImages[0], url: e.target.value };
        setEditedReferanslar({ ...editedReferanslar, images: updatedImages });
    };

    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-referanslar" className="mb-2 inline-block">
                <button className="btn btn-primary">New Referanslar</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Image</TableCell>
                            <TableCell className="tableCell">Firma Name</TableCell>
                            <TableCell className="tableCell">Firma Url</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referanslarstatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={5} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {referanslarstatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={5} className="tableCell">Error: {referanslarerror}</TableCell>
                            </TableRow>
                        )}
                        {referanslarstatus === STATUS.SUCCESS && referanslardata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingReferanslar === row.id ? (
                                        <input
                                            type="text"
                                            value={editedReferanslar.images ? editedReferanslar.images[0]?.url : ''}
                                            onChange={handleImageChange}
                                            placeholder="Edit Image URL"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        <div className="flex items-center">
                                            <img src={row?.images[0]?.url} alt="" className="w-10 h-10 rounded-full mr-1 object-cover" />
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingReferanslar === row.id ? (
                                        <input
                                            type="text"
                                            value={editedReferanslar.name}
                                            onChange={(e) => setEditedReferanslar({ ...editedReferanslar, name: e.target.value })}
                                            placeholder="Edit Name"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.name
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingReferanslar === row.id ? (
                                        <input
                                            type="text"
                                            value={editedReferanslar.url}
                                            onChange={(e) => setEditedReferanslar({ ...editedReferanslar, url: e.target.value })}
                                            placeholder="Edit Url"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.url
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingReferanslar === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ReferanslarList
