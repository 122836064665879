import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

// Helper function to convert Firestore Timestamp to Date
const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp && project.timeStamp.toDate ? project.timeStamp.toDate().toISOString() : project.timeStamp,
    };
};

const initialState = {
    projectdata: [],
    projectstatus: STATUS.IDLE,
    projecterror: null,
};

// Firestore'dan projeleri almak için bir async thunk
export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
    const querySnapshot = await getDocs(collection(db, 'projeler'));
    let projects = [];
    querySnapshot.forEach((doc) => {
        projects.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return projects;
});

// Yeni bir proje oluşturmak için bir async thunk
export const addProject = createAsyncThunk('projects/addProject', async ({ newProject }) => {
    const projectWithImages = { ...newProject, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, "projeler"), projectWithImages);
    return { id: docRef.id, ...projectWithImages };
});

// Proje silmek için bir async thunk
export const deleteProject = createAsyncThunk('projects/deleteProject', async (id) => {
    await deleteDoc(doc(db, "projeler", id));
    return id;
});

// Proje güncellemek için bir async thunk
export const updateProject = createAsyncThunk('projects/updateProject', async ({ id, updatedData }) => {
    const projectDoc = doc(db, "projeler", id);
    await updateDoc(projectDoc, updatedData);
    return { id, updatedData };
});

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.projectstatus = STATUS.LOADING;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.projectstatus = STATUS.SUCCESS;
                state.projectdata = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.projectstatus = STATUS.FAIL;
                state.projecterror = action.error.message;
            })
            .addCase(addProject.pending, (state) => {
                state.projectstatus = STATUS.LOADING;
            })
            .addCase(addProject.fulfilled, (state, action) => {
                state.projectstatus = STATUS.SUCCESS;
                state.projectdata.push(action.payload);
            })
            .addCase(addProject.rejected, (state, action) => {
                state.projectstatus = STATUS.FAIL;
                state.projecterror = action.error.message;
            })
            .addCase(deleteProject.pending, (state) => {
                state.projectstatus = STATUS.LOADING;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.projectstatus = STATUS.SUCCESS;
                state.projectdata = state.projectdata.filter(project => project.id !== action.payload);
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.projectstatus = STATUS.FAIL;
                state.projecterror = action.error.message;
            })
            .addCase(updateProject.pending, (state) => {
                state.projectstatus = STATUS.LOADING;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.projectstatus = STATUS.SUCCESS;
                const { id, updatedData } = action.payload;
                const existingProjectIndex = state.projectdata.findIndex(project => project.id === id);
                if (existingProjectIndex !== -1) {
                    state.projectdata[existingProjectIndex] = { ...state.projectdata[existingProjectIndex], ...updatedData };
                }
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.projectstatus = STATUS.FAIL;
                state.projecterror = action.error.message;
            });
    },
});

export default projectSlice.reducer;
