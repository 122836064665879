import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp.toDate().toISOString(),
    };
};


const initialState = {
    iletisimdata: [],
    iletisimstatus: STATUS.IDLE,
    iletisimerror: null,
};

export const fetchCommunication = createAsyncThunk('communication/fetchCommunication', async () => {
    const querySnapshot = await getDocs(collection(db, 'communication'));
    let communication = [];
    querySnapshot.forEach((doc) => {
        communication.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return communication;
});

// Yeni bir kullanıcı oluşturmak için bir async thunk
export const addCommunication = createAsyncThunk('communication/addCommunication', async ({ newUser }) => {
    const userWithTimestamp = { ...newUser, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, 'communication'), userWithTimestamp);
    const docSnapshot = await getDoc(docRef); // Change here
    return { id: docRef.id, ...convertTimestampToDate(docSnapshot.data()) };
});

// Kullanıcıyı güncellemek için bir async thunk
export const updateCommunication = createAsyncThunk('communication/updateCommunication', async ({ id, updatedData }) => {
    const userRef = doc(db, 'communication', id);
    await updateDoc(userRef, { ...updatedData, timeStamp: serverTimestamp() });
    const updatedSnapshot = await getDoc(userRef); // Change here
    return { id, ...convertTimestampToDate(updatedSnapshot.data()) };
});

// Kullanıcıyı silmek için bir async thunk
export const deleteCommunication = createAsyncThunk('communication/deleteCommunication', async (id) => {
    await deleteDoc(doc(db, 'communication', id));
    return id;
});


const iletisimSlice = createSlice({
    name: 'communication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCommunication.pending, (state) => {
                state.iletisimstatus = STATUS.LOADING;
            })
            .addCase(fetchCommunication.fulfilled, (state, action) => {
                state.iletisimstatus = STATUS.SUCCESS;
                state.iletisimdata = action.payload;
            })
            .addCase(fetchCommunication.rejected, (state, action) => {
                state.iletisimstatus = STATUS.FAIL;
                state.iletisimerror = action.error.message;
            })
            .addCase(addCommunication.pending, (state) => {
                state.iletisimstatus = STATUS.LOADING;
            })
            .addCase(addCommunication.fulfilled, (state, action) => {
                state.iletisimstatus = STATUS.SUCCESS;
                state.iletisimdata.push(action.payload);
            })
            .addCase(addCommunication.rejected, (state, action) => {
                state.iletisimstatus = STATUS.FAIL;
                state.iletisimerror = action.error.message;
            })
            .addCase(updateCommunication.pending, (state) => {
                state.iletisimstatus = STATUS.LOADING;
            })
            .addCase(updateCommunication.fulfilled, (state, action) => {
                state.iletisimstatus = STATUS.SUCCESS;
                const index = state.iletisimdata.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.iletisimdata[index] = action.payload;
                }
            })
            .addCase(updateCommunication.rejected, (state, action) => {
                state.iletisimstatus = STATUS.FAIL;
                state.iletisimerror = action.error.message;
            })
            .addCase(deleteCommunication.pending, (state) => {
                state.iletisimstatus = STATUS.LOADING;
            })
            .addCase(deleteCommunication.fulfilled, (state, action) => {
                state.iletisimstatus = STATUS.SUCCESS;
                state.iletisimdata = state.iletisimdata.filter((user) => user.id !== action.payload);
            })
            .addCase(deleteCommunication.rejected, (state, action) => {
                state.iletisimstatus = STATUS.FAIL;
                state.iletisimerror = action.error.message;
            });
    },
});

export default iletisimSlice.reducer;
