import Sidebar from '../../../components/sidebar/Sidebar.jsx'
import Navbar from '../../../components/navbar/Navbar.jsx'
import ThemeList from "../../../components/List/ThemeList.jsx";

const AdminKurumsal = () => {
    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="listContainer">
                    <ThemeList title={"Theme"} />
                </div>
            </div>
        </div>
    )
}

export default AdminKurumsal
