import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

// Helper function to convert Firestore Timestamp to Date
const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp && project.timeStamp.toDate ? project.timeStamp.toDate().toISOString() : project.timeStamp,
    };
};

const initialState = {
    usersdata: [],
    usersstatus: STATUS.IDLE,
    userserror: null,
};

// Firestore'dan kullanıcıları almak için bir async thunk
export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    let users = [];
    querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return users;
});

// Yeni bir kullanıcı oluşturmak için bir async thunk
export const addUsers = createAsyncThunk('users/addUsers', async ({ newUser }) => {
    const userWithTimestamp = { ...newUser, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, 'users'), userWithTimestamp);
    const docSnapshot = await getDoc(docRef); // Change here
    return { id: docRef.id, ...convertTimestampToDate(docSnapshot.data()) };
});

// Kullanıcıyı güncellemek için bir async thunk
export const updateUsers = createAsyncThunk('users/updateUsers', async ({ id, updatedData }) => {
    const userRef = doc(db, 'users', id);
    await updateDoc(userRef, { ...updatedData, timeStamp: serverTimestamp() });
    const updatedSnapshot = await getDoc(userRef); // Change here
    return { id, ...convertTimestampToDate(updatedSnapshot.data()) };
});

// Kullanıcıyı silmek için bir async thunk
export const deleteUsers = createAsyncThunk('users/deleteUsers', async (id) => {
    await deleteDoc(doc(db, 'users', id));
    return id;
});

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.usersstatus = STATUS.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.usersstatus = STATUS.SUCCESS;
                state.usersdata = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.usersstatus = STATUS.FAIL;
                state.userserror = action.error.message;
            })
            .addCase(addUsers.pending, (state) => {
                state.usersstatus = STATUS.LOADING;
            })
            .addCase(addUsers.fulfilled, (state, action) => {
                state.usersstatus = STATUS.SUCCESS;
                state.usersdata.push(action.payload);
            })
            .addCase(addUsers.rejected, (state, action) => {
                state.usersstatus = STATUS.FAIL;
                state.userserror = action.error.message;
            })
            .addCase(updateUsers.pending, (state) => {
                state.usersstatus = STATUS.LOADING;
            })
            .addCase(updateUsers.fulfilled, (state, action) => {
                state.usersstatus = STATUS.SUCCESS;
                const index = state.usersdata.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.usersdata[index] = action.payload;
                }
            })
            .addCase(updateUsers.rejected, (state, action) => {
                state.usersstatus = STATUS.FAIL;
                state.userserror = action.error.message;
            })
            .addCase(deleteUsers.pending, (state) => {
                state.usersstatus = STATUS.LOADING;
            })
            .addCase(deleteUsers.fulfilled, (state, action) => {
                state.usersstatus = STATUS.SUCCESS;
                state.usersdata = state.usersdata.filter((user) => user.id !== action.payload);
            })
            .addCase(deleteUsers.rejected, (state, action) => {
                state.usersstatus = STATUS.FAIL;
                state.userserror = action.error.message;
            });
    },
});

export default usersSlice.reducer;
