import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import ProjectList from "../../../components/List/ProjectList";

const AdminProjeler = () => {


    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="listContainer">
                    <ProjectList title={"Projeler"} />
                </div>
            </div>
        </div>
    )
}

export default AdminProjeler
