import { useState } from "react";
import { useDispatch } from "react-redux";
import { addUsers } from "../../../redux/usersSlice";
import { toast } from "react-toastify";
import { uploadImage } from "../../../utils/uploadImage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../sidebar/Sidebar";
import Navbar from "../../navbar/Navbar";
import { CircularProgress } from "@mui/material";

const NewUser = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [progress, setProgress] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isKvkkAccepted, setIsKvkkAccepted] = useState(false);
    const [user, setUser] = useState({
        displayName: "",
        job: "",
        jobyear: "",
        phone: "",
        age: "",
        country: "",
        email: "",
        address: "",
        kvkk: false,  // KVKK onayı için yeni alan
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value });
    };

    const handleFilesChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);

        // Create previews for the selected files
        const selectedPreviews = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviews(selectedPreviews);
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Resimleri sıkıştır ve yükle
            const imageUploadResults = await Promise.all(files.map(file =>
                uploadImage(file, (progress) => {
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress
                    }));
                })
            ));

            // Dosya adlarını ve URL'lerini ayrı olarak al
            const imagePaths = imageUploadResults.map((url, index) => ({
                url: url.downloadURL,
                urlname: url.newFileName,
                path: `assets/users/${url.newFileName}`, // Boşlukları kaldır ve '-' ile değiştir
            }));

            // Veriyi Firebase Firestore'a gönder
            const userData = {
                ...user,
                images: imagePaths,
            };

            await dispatch(addUsers({ newUser: userData }));

            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");

            // İşlem başarılı ise formu temizle
            setUser({
                displayName: "",
                job: "",
                jobyear: "",
                phone: "",
                age: "",
                country: "",
                email: "",
                address: "",
                kvkk: false,
            });
            setFiles([]); // Dosya seçim alanını temizle
            setPreviews([]); // Önizleme alanını temizle
            setProgress({}); // Yükleme ilerlemesini temizle

        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">New User</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label htmlFor="files" className="block text-gray-700 font-medium mb-2">
                                    Images
                                    <DriveFolderUploadOutlinedIcon className="ml-2 text-gray-500" />
                                </label>
                                <input
                                    type="file"
                                    id="files"
                                    multiple
                                    onChange={handleFilesChange}
                                    className="hidden"
                                />
                                <label htmlFor="files" className="cursor-pointer p-2 bg-blue-500 text-white rounded-md inline-block">Upload Images</label>
                                <div className="flex flex-wrap mt-2 gap-2">
                                    {previews.map((preview, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="w-24 h-24 object-cover border border-gray-300 rounded-lg"
                                            />
                                            <div className="absolute bottom-0 left-0 w-full bg-gray-700 bg-opacity-50 text-white text-xs text-center rounded-b-lg">
                                                {progress[files[index].name] ? `${Math.round(progress[files[index].name])}%` : '0%'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="title">Name Surname</label>
                                <input
                                    id="displayName"
                                    type="text"
                                    placeholder="Enter Name Surname"
                                    onChange={handleInput}
                                    value={user.displayName}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="job">Job</label>
                                <input
                                    id="job"
                                    type="text"
                                    placeholder="Enter Job"
                                    onChange={handleInput}
                                    value={user.job}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="jobyear">Job Year</label>
                                <input
                                    id="jobyear"
                                    placeholder="Enter Job Year"
                                    onChange={handleInput}
                                    value={user.jobyear}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="year">Phone</label>
                                <input
                                    id="phone"
                                    type="text"
                                    placeholder="Enter Phone"
                                    onChange={handleInput}
                                    value={user.phone}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="age">Age</label>
                                <input
                                    id="age"
                                    type="text"
                                    placeholder="Enter Age"
                                    onChange={handleInput}
                                    value={user.age}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="country">Country</label>
                                <input
                                    id="country"
                                    type="text"
                                    placeholder="Enter country"
                                    onChange={handleInput}
                                    value={user.country}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    type="text"
                                    placeholder="Enter Email"
                                    onChange={handleInput}
                                    value={user.email}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="address">Adres</label>
                                <input
                                    id="address"
                                    type="text"
                                    placeholder="Adresinizi Giriniz..."
                                    onChange={handleInput}
                                    value={user.address}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <a href="/privacy" target="_blank" rel="noopener noreferrer">KVKK Metni</a>
                            </div>
                            <div className="mb-4 flex items-center">
                                <input
                                    type="checkbox"
                                    id="kvkk"
                                    checked={isKvkkAccepted}
                                    onChange={() => {
                                        setIsKvkkAccepted(!isKvkkAccepted);
                                        setUser({ ...user, kvkk: !isKvkkAccepted });
                                    }}
                                    className="mr-2"
                                />
                                <label htmlFor="kvkk" className="text-gray-700">KVKK onayını kabul ediyorum.</label>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    disabled={!isKvkkAccepted || isLoading}
                                    className={`px-4 py-2 ${isKvkkAccepted ? 'bg-blue-500' : 'bg-gray-500'} text-white rounded-md`}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : "CV Gönder"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUser;
