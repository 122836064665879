// navigation Data
export const navItems = [
    {
        title: "Ana Sayfa",
        url: "/",
    },
    {
        title: "Kurumsal",
        url: "/corporate",
    },
    {
        title: "Hakkında",
        url: "/about",
    },
    {
        title: "Projelerimiz",
        url: "/projects",
    },
    {
        title: "İletişim",
        url: "/communication",
    }

];

export const projects = [
    {
        "id": 1,
        "title": "Mamak Kredi Yurtlar Kurumu",
        "description": "<div><p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> İhtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Bina içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p></div>",
        "images": [
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/1FDZ9U7KTGWAQ1AZ.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/D6H7KBJ1PFNGLQO4.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/YJ9KGRJGNMUMHTN9.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/TJJUYQEAI4FI7MGI.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/374SL89OHET72D9N.jpg"
        ]
    },
    {
        "id": 2,
        "title": "Edirne Sultan Çelebi Mehmet Öğrenci Yurdu",
        "description": "<div><p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Yurtta ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Yurt içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p></div>",
        "images": [
            "https://kygm.gsb.gov.tr/public/YurtFotoYeni/edirne/022138502/thumbs/011$650x433.jpg",
            "https://kygm.gsb.gov.tr/public/YurtFotoYeni/edirne/022138502/thumbs/012$650x433.jpg",
            "https://kygm.gsb.gov.tr/public/YurtFotoYeni/edirne/022138502/thumbs/052$650x433.jpg",
            "https://kygm.gsb.gov.tr/public/YurtFotoYeni/edirne/022138502/thumbs/061$650x433.jpg",
            "https://kygm.gsb.gov.tr/public/YurtFotoYeni/edirne/022138502/thumbs/081$650x433.jpg"
        ]
    },
    {
        "id": 3,
        "title": "Edirne Kyk Kız Öğrenci Yurdu",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Yurtta ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Yurt içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54539/SL1BZJ67H97QJ3XF.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54536/RUHECJK54VNSVAVU.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54539/BYWP7P8QTPNO79FQ.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54539/SXSHB18ANCXV74H2.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54539/KG58A1BPB1XX8PDN.jpg"
        ]
    },
    {
        "id": 4,
        "title": "Kocaeli Sultan Baba Kredi Yurtlar Kurumu",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Yurtta ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Yurt içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54753/BL9ERGGTIDJJ4HBP.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54753/UX61U6BXWR1TV9LX.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54753/NDLZPQ7WOD172GBF.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54753/MFJRT7EDLGZVXUHJ.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54753/1OLIOKI8O8CUHRSB.jpg"
        ]
    },
    {
        "id": 5,
        "title": "Ordu Kredi Yurtlar Kurumu",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Yurtta ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Yurt içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54865/IUWQ6SR4PXMOJIJA.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54865/QIHOSAMHN8ZDF9CF.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54865/CU81ORMWKF7JABCK.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54865/8E798A2YAQKEGHM1.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54865/ZGML2XXWJIF1YEWH.jpg"
        ]
    },
    {
        "id": 6,
        "title": "Niğde Kredi Yurtlar Kurumu",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Yurtta ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Yurt içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin yurt içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54857/JFV8HHGLGDAJ11WE.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54857/GG1AS7HPTNKBS4EL.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54857/LLMNVQKPK6D6ZMA3.jpg",
            "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54857/F4R5MISGRO253IVL.jpg"
        ]
    },
    {
        "id": 7,
        "title": "Özel Koru Hastanesi",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> Hastahanede ihtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Hastahane içinde aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin hastahane içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.koruhastanesi.com/images/pages/galeri/main/911202115585991249e48a82c-5c25-4b5e-aa7e-49b65f0a8e55.jpg",
            "https://www.koruhastanesi.com/images/pages/galeri/medium/101120211041415861965aaa32-1208-41b0-a68f-f92691f690f4.JPG",
            "https://www.koruhastanesi.com/images/pages/galeri/medium/9112021155859866112d4fb33b-0233-4ba4-9288-6e1e7ef923f0.jpg",
            "https://www.koruhastanesi.com/images/pages/galeri/medium/9112021155859975545d402b5-c25f-4980-af65-c3821849e856.jpg"
        ]
    },
    {
        "id": 8,
        "title": "ADANOVA V.İ.P",
        "description": "<p> <span style='font-weight: bold;'>Elektrik Tesisatı Tasarımı: </span> İhtiyaç duyulan elektrik sistemlerinin tasarımı, planlanması ve uygulanması.</p><p><span style='font-weight: bold;'>Elektrik Tesisatı Kurulumu:</span> Elektrik tesisatının fiziksel olarak kurulması, kabloların çekilmesi, prizlerin, anahtarların ve diğer elektrik donanımlarının yerleştirilmesi.</p><p><span style='font-weight: bold;'>Aydınlatma Sistemleri:</span> Aydınlatma sistemlerinin kurulumu ve yönetimi.</p><p><span style='font-weight: bold;'>Enerji Verimliliği Çözümleri:</span> Elektrik tesisatında enerji verimliliğini artırmak amacıyla çeşitli çözümler ve teknolojilerin uygulanması.</p><p><span style='font-weight: bold;'>Güç Dağıtımı: </span>Elektrik enerjisinin bina içinde dengeli bir şekilde dağıtılması ve güvenli bir şekilde kullanılması.</p><p><span style='font-weight: bold;'>Sistem Testleri ve Bakım:</span> Kurulan sistemlerin düzgün çalıştığından emin olmak için testlerin yapılması ve düzenli bakımın sağlanması.</p>",
        "images": [
            "https://www.adanovayapi.com/wp-content/uploads/2023/12/DSCF1359-1-scaled.jpg",
            "https://www.adanovayapi.com/wp-content/uploads/2023/12/GENEL-DETAY-39-scaled.jpg",
            "https://hecdnw01.hemlak.com/ds01/1/2/6/1/5/0/6/3/326797cb-e955-432d-97bd-e04742b5de88.jpg",
            "https://hecdnw01.hemlak.com/ds01/1/2/6/1/5/0/6/3/b47a2732-dca2-43d1-b988-950fdd234052.jpg"
        ]
    }
];

export const carouselimg = [
    {
        "id": 1,
        "title": "Yüksek sorumluluk duygusu",
        "images": [
            "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1474000/elektrik-1475431.jpg",
        ]
    },
    {
        "id": 2,
        "title": "Güvenilirlik",
        "images": [
            "https://www.sedsalenerji.com/wp-content/uploads/2019/11/elektrik.jpeg",
        ]
    },
    {
        "id": 3,
        "title": "Deneyim",
        "images": [
            "https://www.kutahyaosb.org.tr/tema/genel/uploads/birimler/28218782_m-1536x1003.jpg",
        ]
    },
    {
        "id": 4,
        "title": "Kalite bilinci",
        "images": [
            "https://niluferelektrik.com.tr/tema/firma/uploads/slider/electric_cable_green_1920x800_res_wl2_slider_1920.jpg",
        ]
    },
    {
        "id": 5,
        "title": "Sürekli gelişim inancı",
        "images": [
            "https://vataelektrik.com/wp-content/uploads/revslider/finance/vata-elektrik-izmir.jpg",
        ]
    },
    {
        "id": 6,
        "title": "İş ahlakına bağlılık",
        "images": [
            "https://www.ostimteknik.edu.tr/content/upload/services/electricity-20200428142838.jpg",
        ]
    },
    {
        "id": 7,
        "title": "Örnek Elektrik Projesi",
        "images": [
            "https://www.koruhastanesi.com/images/pages/galeri/main/652024103317462649b0804-428f-4b23-a829-6d3bb27522c5.png",
        ]
    },
    {
        "id": 8,
        "title": "Örnek Elektrik Projesi",
        "images": [
            "https://www.adanovayapi.com/wp-content/uploads/2023/12/DSCF1359-1-scaled.jpg",
        ]
    }

];

export const references = [
    {
        "id": 1,
        "name": "RAMS",
        "description": "Rams",
        "link": "https://ramsturkiye.com.tr/tr",
        "image": "https://cdn02.hemlak.com/Qlh1VzJXb05WM0hHV0VkQ0kyZ091cEVMY0ZnMDYvUVVjdHBKQnJBc1I1RStodVVrc3FIby9IMjNhVFoyYzVRV3I3NW1GdFBXelJGZnlzUXNKdzd3ZVE9PQ==.jpg",
    },
    {
        "id": 2,
        "name": "Bayraktar İnşaat",
        "description": "Bayraktarİnsaat",
        "link": "http://www.bayraktarinsaat.com.tr/",
        "image": "https://www.hepsikonut.com/images/firmalar/2017/03/bayraktar-insaat.jpg",
    },
    {
        "id": 3,
        "name": "Emlak Konut",
        "description": "EmlakKonut",
        "link": "https://www.emlakkonut.com.tr/",
        "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrPPWOuOp5qulNeA0GBpJzwVcckxF4bLizsOJx8WB86g&s",
    },
    {
        "id": 4,
        "name": "Toki",
        "description": "TOKİ",
        "link": "https://www.toki.gov.tr/",
        "image": "https://cagrimerkezimiz.com/wp-content/uploads/2017/12/toki-%C3%A7a%C4%9Fr%C4%B1-merkezi-ileti%C5%9Fim-m%C3%BC%C5%9Fteri-hizmetleri-telefon-numaras%C4%B1.jpg",
    },
    {
        "id": 5,
        "name": "Kredi Yurtlar Kurumu",
        "description": "KrediYurtlarKurumu",
        "link": "https://kygm.gsb.gov.tr/",
        "image": "https://noalife.com.tr/uploads/2023/08/kyklogo-1.jpg.webp",
    },
    {
        "id": 6,
        "name": "Gençlik Spor Bakanlığı",
        "description": "Gençlik Spor Bakanlığı",
        "link": "https://gsb.gov.tr/",
        "image": "https://upload.wikimedia.org/wikipedia/en/thumb/a/ab/Ministry_of_Youth_and_Sports_%28Turkey%29_logo.svg/1200px-Ministry_of_Youth_and_Sports_%28Turkey%29_logo.svg.png",
    },
    {
        "id": 7,
        "name": "Tuğba Ambalaj",
        "description": "Tuğba Ambalaj",
        "link": "https://www.tugbaambalaj.com/",
        "image": "https://media.licdn.com/dms/image/C4E0BAQFMkMSDuO2z5Q/company-logo_200_200/0/1630625807568?e=2147483647&v=beta&t=J6KC7F7gDM5ihGf92L4WoBbfuG0dFO3jfFcG7Ojv604",
    },
    {
        "id": 8,
        "name": "Adanova Yapı",
        "description": "Adanova Yapı",
        "link": "https://www.adanovayapi.com/",
        "image": "https://morza.com.tr/wp-content/uploads/2021/05/adanova-mobilya.png",
    },
    {
        "id": 9,
        "name": "Sağlık Bakanlığı",
        "description": "Sağlık Bakanlığı",
        "link": "https://www.saglik.gov.tr/",
        "image": "https://yt3.googleusercontent.com/ytc/AIdro_nnpxTpH03bCbzY669bkxx1TZwASriiRHijPaL9NaM3kSI=s900-c-k-c0x00ffffff-no-rj",
    },
    {
        "id": 10,
        "name": "İlk Adim Gayrimenkul",
        "description": "İlk Adim Gayrimenkul",
        "link": "https://www.ilkadimgayrimenkul.com/",
        "image": "https://image5.sahibinden.com/stores/logos/00/42/98/6054ed9c321f3bbb0b597eb4341b763fb8d0f598.png",
    },



];

export const shopDukkan = [
    {
        id: 1,
        title: "DukkanOrta",
        description: "DukkanOrta",
        url: "https://res.cloudinary.com/dafvzct6j/image/upload/v1715761588/gurgenassets/lrcom4t63kttzsizqwwi.jpg"
    },
    {
        id: 2,
        title: "Dukkan",
        description: "Dükkan",
        url: "https://res.cloudinary.com/dafvzct6j/image/upload/v1715761587/gurgenassets/mziuwmdrym0y7r5um9sl.jpg"
    },
    {
        id: 3,
        title: "Dukkan",
        description: "Dükkan",
        url: "https://res.cloudinary.com/dafvzct6j/image/upload/v1715761586/gurgenassets/u4hdfhcsrszq9nwqif48.jpg"
    },
    {
        id: 4,
        title: "DukkanTabela",
        description: "DukkanTabela",
        url: "https://res.cloudinary.com/dafvzct6j/image/upload/v1715761590/gurgenassets/k3kykp8mvdcqpusyirxs.jpg"
    },
    {
        id: 5,
        title: "DükkanOnTaraf",
        description: "DükkanOnTaraf",
        url: "https://res.cloudinary.com/dafvzct6j/image/upload/v1715761592/gurgenassets/ommsqc7zjjo2mwtf9ibx.jpg"
    },
]

export const rows = [
    {
        id: 1143155,
        title: "Mamak KYK Elektrik Projesi",
        image: "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/1FDZ9U7KTGWAQ1AZ.jpg",
        company: "Kredi Yurtlar Kurumu",
        year: "2010",
        status: "Tamamlandı",
    },
    {
        id: 1143135,
        title: "Mamak KYK Elektrik Projesi",
        image: "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/1FDZ9U7KTGWAQ1AZ.jpg",
        company: "Kredi Yurtlar Kurumu",
        year: "2010",
        status: "Devam Ediyor",
    },
    {
        id: 1143235,
        title: "Mamak KYK Elektrik Projesi",
        image: "https://www.kykyurtlar.com/kykyurtlar/upload/company/4/54406/1FDZ9U7KTGWAQ1AZ.jpg",
        company: "Kredi Yurtlar Kurumu",
        year: "2010",
        status: "Onaylı",
    },
];