import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../utils/firebase';
import { collection, getDocs, addDoc, updateDoc, doc, serverTimestamp, deleteDoc, getDoc } from 'firebase/firestore';
import { STATUS } from '../utils/status';

const initialState = {
    themes: [],
    status: STATUS.IDLE,
    error: null,
    currentTheme: null, // Eklenen alan
};

const convertTimestampToDate = (project) => {
    const convertedProject = { ...project };
    if (convertedProject.timeStamp && convertedProject.timeStamp.toDate) {
        convertedProject.timeStamp = convertedProject.timeStamp.toDate().toISOString();
    }
    return convertedProject;
};

export const fetchThemes = createAsyncThunk('theme/fetchThemes', async () => {
    const querySnapshot = await getDocs(collection(db, 'themes'));
    const themes = [];
    querySnapshot.forEach((doc) => {
        themes.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return themes;
});

export const addTheme = createAsyncThunk('theme/addTheme', async (theme) => {
    if (theme.isDefault) {
        const querySnapshot = await getDocs(collection(db, 'themes'));
        querySnapshot.forEach(async (docItem) => {
            if (docItem.data().isDefault) {
                await updateDoc(doc(db, 'themes', docItem.id), { isDefault: false });
            }
        });
    }
    const projectWithImages = { ...theme, timeStamp: serverTimestamp() };

    const docRef = await addDoc(collection(db, 'themes'), projectWithImages);
    const docData = await getDoc(docRef);
    return { id: docRef.id, ...convertTimestampToDate(docData.data()) };
});

// updateTheme thunk
export const updateTheme = createAsyncThunk('theme/updateTheme', async ({ id, updatedData }) => {
    const projectDocRef = doc(db, "themes", id);
    const projectWithImages = { ...updatedData, timeStamp: serverTimestamp() };

    await updateDoc(projectDocRef, projectWithImages);

    return { id, ...convertTimestampToDate(projectWithImages) };
});

// deleteTheme thunk
export const deleteTheme = createAsyncThunk('theme/deleteTheme', async (id) => {
    await deleteDoc(doc(db, "themes", id));
    return id;
});

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.currentTheme = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // fetchThemes cases
            .addCase(fetchThemes.pending, (state) => {
                state.status = STATUS.LOADING;
            })
            .addCase(fetchThemes.fulfilled, (state, action) => {
                state.status = STATUS.SUCCESS;
                state.themes = action.payload;

            })
            .addCase(fetchThemes.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.error.message;
            })

            // addTheme cases
            .addCase(addTheme.pending, (state) => {
                state.status = STATUS.LOADING;
            })
            .addCase(addTheme.fulfilled, (state, action) => {
                state.themes.push(action.payload);
            })
            .addCase(addTheme.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.error.message;
            })

            // deleteTheme cases
            .addCase(deleteTheme.pending, (state) => {
                state.status = STATUS.LOADING;
            })
            .addCase(deleteTheme.fulfilled, (state, action) => {
                state.status = STATUS.SUCCESS;
                state.themes = state.themes.filter(theme => theme.id !== action.payload);
            })
            .addCase(deleteTheme.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.error.message;
            })

            // updateTheme cases
            .addCase(updateTheme.pending, (state) => {
                state.status = STATUS.LOADING;
            })
            .addCase(updateTheme.fulfilled, (state, action) => {
                const index = state.themes.findIndex(theme => theme.id === action.payload.id);
                if (index !== -1) {
                    state.themes[index] = action.payload;
                }
                state.status = STATUS.SUCCESS;
            })
            .addCase(updateTheme.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.error.message;
            });
    },
});

export default themeSlice.reducer;
