import { useEffect, useRef, useState } from 'react';
import myAudioFile from '../assets/mp3/KitaroCaravansary(live).mp3';

const BackgroundMusic = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play().catch(error => console.error("Audio play error:", error));
        }
        setIsPlaying(!isPlaying);
    };

    const handleVolumeChange = (e) => {
        const audio = audioRef.current;
        audio.volume = e.target.value;
        setVolume(e.target.value);
    };

    const handleTimeUpdate = () => {
        const audio = audioRef.current;
        setCurrentTime(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
        const audio = audioRef.current;
        setDuration(audio.duration);
    };

    const handleSeek = (e) => {
        const audio = audioRef.current;
        audio.currentTime = e.target.value;
        setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        setCurrentTime(0);
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('error', (e) => console.error("Audio error:", e));

        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('error', (e) => console.error("Audio error:", e));
        };
    }, []);

    return (
        <div className="flex items-center justify-center p-1 rounded-lg shadow-md w-full md:w-72">
            <div className="w-24 mr-4">
                <audio ref={audioRef} src={myAudioFile} />
                <button
                    onClick={togglePlayPause}
                    className="p-2 bg-gray-400 text-white rounded-md w-full"
                >
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
            </div>
            <div className="flex flex-col">
                <div className="grid grid-rows-2 justify-start w-full">
                    <input
                        type="range"
                        min="0"
                        max={duration}
                        step="0.1"
                        value={currentTime}
                        onChange={handleSeek}
                        className="w-full"
                    />
                    <span className="ml-2 text-gray-700 text-sm">
                        {Math.floor(currentTime / 60)}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
                    </span>
                </div>
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="w-full mt-2"
                />
            </div>
        </div>
    );
};

export default BackgroundMusic;
