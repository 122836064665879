import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../utils/status';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteCorporate, fetchCorporate, updateCorporate } from './../../redux/corporateSlice';

const KurumsalList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingCorporate, setEditingCorporate] = useState(null);
    const [editedCorporate, setEditedCorporate] = useState({});

    const { corporatedata, corporatestatus, corporateerror } = useSelector((state) => state.corporate);

    useEffect(() => {
        if (corporatestatus === STATUS.IDLE) {
            dispatch(fetchCorporate());
        }
    }, [corporatestatus, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteCorporate(id));
    };

    const handleUpdate = (id) => {
        // Convert the degerler string to an array
        const updatedData = {
            ...editedCorporate,
            degerler: editedCorporate.degerler.split(',').map(item => item.trim()),
            hedefler: editedCorporate.hedefler.split(',').map(item => item.trim())
        };
        dispatch(updateCorporate({ id, updatedData }));
        setEditingCorporate(null);
        setEditedCorporate({});
    };

    const handleEdit = (user) => {
        // Convert the degerler array to a comma-separated string
        setEditingCorporate(user.id);
        setEditedCorporate({
            ...user,
            degerler: user.degerler.join(', '),
            hedefler: user.hedefler.join(', ')
        });
    };

    const handleCancel = () => {
        setEditingCorporate(null);
        setEditedCorporate({});
    };

    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-corporate" className="mb-2 inline-block">
                <button className="btn btn-primary">New Corporate</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Değerler</TableCell>
                            <TableCell className="tableCell">Hedefler</TableCell>
                            <TableCell className="tableCell">Kalite Politikası</TableCell>
                            <TableCell className="tableCell">Misyon</TableCell>
                            <TableCell className="tableCell">Vizyon</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {corporatestatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {corporatestatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Error: {corporateerror}</TableCell>
                            </TableRow>
                        )}
                        {corporatestatus === STATUS.SUCCESS && corporatedata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingCorporate === row.id ? (
                                        <input
                                            type="text"
                                            value={editedCorporate.degerler}
                                            onChange={(e) => setEditedCorporate({ ...editedCorporate, degerler: e.target.value })}
                                            placeholder="Edit Değerler"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.degerler ? row.degerler.join(", ") : ""
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingCorporate === row.id ? (
                                        <input
                                            type="text"
                                            value={editedCorporate.hedefler}
                                            onChange={(e) => setEditedCorporate({ ...editedCorporate, hedefler: e.target.value })}
                                            placeholder="Edit Vizyon"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.hedefler ? row.hedefler.join(', ') : ""
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingCorporate === row.id ? (
                                        <input
                                            type="text"
                                            value={editedCorporate.kalitepolitikasi}
                                            onChange={(e) => setEditedCorporate({ ...editedCorporate, kalitepolitikasi: e.target.value })}
                                            placeholder="Edit Kalite Politikasi"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.kalitepolitikasi
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingCorporate === row.id ? (
                                        <input
                                            type="text"
                                            value={editedCorporate.misyon}
                                            onChange={(e) => setEditedCorporate({ ...editedCorporate, misyon: e.target.value })}
                                            placeholder="Edit Misyon"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.misyon
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingCorporate === row.id ? (
                                        <input
                                            type="text"
                                            value={editedCorporate.vizyon}
                                            onChange={(e) => setEditedCorporate({ ...editedCorporate, vizyon: e.target.value })}
                                            placeholder="Edit Vizyon"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.vizyon
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingCorporate === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default KurumsalList;
