import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp.toDate().toISOString(),
    };
};


const initialState = {
    konumdata: [],
    konumstatus: STATUS.IDLE,
    konumerror: null,
};

export const fetchKonum = createAsyncThunk('projects/fetchKonum', async () => {
    const querySnapshot = await getDocs(collection(db, 'konum'));
    let konum = [];
    querySnapshot.forEach((doc) => {
        konum.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return konum;
});


// Yeni bir kullanıcı oluşturmak için bir async thunk
export const addKonum = createAsyncThunk('referanslar/addKonum', async ({ newUser }) => {
    const userWithTimestamp = { ...newUser, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, 'konum'), userWithTimestamp);
    const docSnapshot = await getDoc(docRef); // Change here
    return { id: docRef.id, ...convertTimestampToDate(docSnapshot.data()) };
});

// Kullanıcıyı güncellemek için bir async thunk
export const updateKonum = createAsyncThunk('referanslar/updateKonum', async ({ id, updatedData }) => {
    const userRef = doc(db, 'konum', id);
    await updateDoc(userRef, { ...updatedData, timeStamp: serverTimestamp() });
    const updatedSnapshot = await getDoc(userRef); // Change here
    return { id, ...convertTimestampToDate(updatedSnapshot.data()) };
});

// Kullanıcıyı silmek için bir async thunk
export const deleteKonum = createAsyncThunk('Konum/deleteKonum', async (id) => {
    await deleteDoc(doc(db, 'konum', id));
    return id;
});

const konumSlice = createSlice({
    name: 'konum',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKonum.pending, (state) => {
                state.konumstatus = STATUS.LOADING;
            })
            .addCase(fetchKonum.fulfilled, (state, action) => {
                state.konumstatus = STATUS.SUCCESS;
                state.konumdata = action.payload;
            })
            .addCase(fetchKonum.rejected, (state, action) => {
                state.konumstatus = STATUS.FAIL;
                state.konumerror = action.error.message;
            })
            .addCase(addKonum.pending, (state) => {
                state.konumstatus = STATUS.LOADING;
            })
            .addCase(addKonum.fulfilled, (state, action) => {
                state.konumstatus = STATUS.SUCCESS;
                state.konumdata.push(action.payload);
            })
            .addCase(addKonum.rejected, (state, action) => {
                state.konumstatus = STATUS.FAIL;
                state.konumerror = action.error.message;
            })
            .addCase(updateKonum.pending, (state) => {
                state.konumstatus = STATUS.LOADING;
            })
            .addCase(updateKonum.fulfilled, (state, action) => {
                state.konumstatus = STATUS.SUCCESS;
                const index = state.konumdata.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.konumdata[index] = action.payload;
                }
            })
            .addCase(updateKonum.rejected, (state, action) => {
                state.konumstatus = STATUS.FAIL;
                state.konumerror = action.error.message;
            })
            .addCase(deleteKonum.pending, (state) => {
                state.konumstatus = STATUS.LOADING;
            })
            .addCase(deleteKonum.fulfilled, (state, action) => {
                state.konumstatus = STATUS.SUCCESS;
                state.konumdata = state.konumdata.filter((user) => user.id !== action.payload);
            })
            .addCase(deleteKonum.rejected, (state, action) => {
                state.konumstatus = STATUS.FAIL;
                state.konumerror = action.error.message;
            });
    },
});

export default konumSlice.reducer;
