import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import ReferanslarList from "../../../components/List/ReferanslarList.jsx";

const AdminReferanslar = () => {
    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="listContainer">
                    <ReferanslarList title={"Referanslar"} />
                </div>
            </div>
        </div>
    )
}

export default AdminReferanslar
