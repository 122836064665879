import ReactDOM from 'react-dom/client'
import './index.css'


import { Provider } from 'react-redux';
import { store } from './redux/store.js';

import 'react-circular-progressbar/dist/styles.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
import '@mantine/core/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { MantineProvider } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
import App from './App.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <MantineProvider >
      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Bounce
      />
    </MantineProvider>
  </Provider>

)
