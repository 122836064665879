import { BrowserRouter, Route, Routes } from 'react-router-dom';

//User Everyone
import AnaSayfa from './pages/Home/Home.jsx';
import LocationPage from './pages/LocationPage.jsx';
import CommunicationPage from './pages/CommunicationPage.jsx';
import AboutPage from './pages/AboutPage.jsx';
import ContactusPage from './pages/ContactusPage.jsx';
import ProjectsPage from './pages/ProjectsPage.jsx';
import CorporatePage from './pages/CorporatePage.jsx';
import ProjectsDetailPage from './pages/ProjectsDetailPage.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import PageNotFound from './pages/PageNotFound404.jsx';
import Login from './pages/auth/Login.jsx';
import Dashboard from './pages/auth/Dashboard.jsx';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './routers/ProtectedRoute';

//Admin 
import AdminUsers from './pages/users/Admin/AdminUsers.jsx';
import AdminSlider from './pages/users/Admin/AdminSlider.jsx';
import AdminProjeler from './pages/users/Admin/AdminProjeler.jsx';
import AdminReferanslar from './pages/users/Admin/AdminReferanslar.jsx';
import AdminNotification from './pages/users/Admin/AdminNotification.jsx';
import AdminProfile from './pages/users/Admin/AdminProfile.jsx';
import AdminKurumsal from './pages/users/Admin/AdminKurumsal.jsx';
import AdminHakkinda from './pages/users/Admin/AdminHakkinda.jsx';
import AdminIletisim from './pages/users/Admin/AdminIletisim.jsx';
import AdminKonum from './pages/users/Admin/AdminKonum.jsx';
import AdminTheme from './pages/users/Admin/AdminTheme.jsx';
import NewProjeler from './components/List/Project/NewProject.jsx';
import NewUser from './components/List/Project/NewUser.jsx';
import NewSlider from './components/List/Project/NewSlider.jsx';
import NewReferanslar from './components/List/Project/NewReferanslar.jsx';
import NewKonum from './components/List/Project/Newkonum.jsx';
import NewIletisim from './components/List/Project/NewIletisim.jsx';
import NewKurumsal from './components/List/Project/NewKurumsal.jsx';
import NewHakkinda from './components/List/Project/NewHakkinda.jsx';
import NewTheme from './components/List/Project/NewTheme.jsx';
import { useEffect } from 'react';
import { fetchReferanslar } from './redux/referansSlice.js';
import { fetchKonum } from './redux/konumSlice.js';
import { fetchCommunication } from './redux/iletisimSlice.js';
import { fetchAbout } from './redux/aboutSlice.js';
import { fetchCorporate } from './redux/corporateSlice.js';
import { fetchProjects } from './redux/projectSlice.js';
import { fetchUsers } from './redux/usersSlice.js';
import { fetchSlider } from './redux/sliderSlice.js';
import { fetchThemes } from './redux/themeSlice';
import NewJob from './pages/NewJob';
import Privacy from './pages/Privacy.jsx';

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchReferanslar());
        dispatch(fetchKonum());
        dispatch(fetchCommunication());
        dispatch(fetchAbout());
        dispatch(fetchCorporate());
        dispatch(fetchProjects());
        dispatch(fetchUsers());
        dispatch(fetchSlider());
        dispatch(fetchThemes());
    }, [dispatch]);

    const { user } = useSelector((state) => state.admin); // state.admin'dan user'ı alıyoruz
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route index path="/" element={<AnaSayfa />} />
                <Route path="/location" element={<LocationPage />} />
                <Route path="/communication" element={<CommunicationPage />} />
                <Route path="/contactus" element={<ContactusPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/projects" element={<ProjectsPage />} />/
                <Route path="/corporate" element={<CorporatePage />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/new-jobkayit" element={<NewJob />} />
                <Route path="/projects/:projectId" element={<ProjectsDetailPage />} />
                <Route path="/*" element={<PageNotFound />} />
                <Route path="/login" element={user ? <Dashboard /> : <Login />} />
                <Route path="/adminusers" element={user ? <ProtectedRoute><AdminUsers /></ProtectedRoute> : <Login />} />
                <Route path="/new-user" element={user ? <ProtectedRoute><NewUser /></ProtectedRoute> : <Login />} />
                <Route path="/adminslider" element={user ? <ProtectedRoute><AdminSlider /></ProtectedRoute> : <Login />} />
                <Route path="/new-slider" element={user ? <ProtectedRoute><NewSlider /></ProtectedRoute> : <Login />} />
                <Route path="/adminprojeler" element={user ? <ProtectedRoute><AdminProjeler /></ProtectedRoute> : <Login />} />
                <Route path="/new-project" element={user ? <ProtectedRoute><NewProjeler /></ProtectedRoute> : <Login />} />
                <Route path="/adminreferanslar" element={user ? <ProtectedRoute><AdminReferanslar /></ProtectedRoute> : <Login />} />
                <Route path="/new-referanslar" element={user ? <ProtectedRoute><NewReferanslar /></ProtectedRoute> : <Login />} />
                <Route path="/adminnotification" element={user ? <ProtectedRoute><AdminNotification /></ProtectedRoute> : <Login />} />
                <Route path="/adminkurumsal" element={user ? <ProtectedRoute><AdminKurumsal /></ProtectedRoute> : <Login />} />
                <Route path="/new-corporate" element={user ? <ProtectedRoute><NewKurumsal /></ProtectedRoute> : <Login />} />
                <Route path="/adminhakkinda" element={user ? <ProtectedRoute><AdminHakkinda /></ProtectedRoute> : <Login />} />
                <Route path="/new-about" element={user ? <ProtectedRoute><NewHakkinda /></ProtectedRoute> : <Login />} />
                <Route path="/adminiletisim" element={user ? <ProtectedRoute><AdminIletisim /></ProtectedRoute> : <Login />} />
                <Route path="/new-iletisim" element={user ? <ProtectedRoute><NewIletisim /></ProtectedRoute> : <Login />} />
                <Route path="/adminkonum" element={user ? <ProtectedRoute><AdminKonum /></ProtectedRoute> : <Login />} />
                <Route path="/new-konum" element={user ? <ProtectedRoute><NewKonum /></ProtectedRoute> : <Login />} />
                <Route path="/admintheme" element={user ? <ProtectedRoute><AdminTheme /></ProtectedRoute> : <Login />} />
                <Route path="/new-theme" element={user ? <ProtectedRoute><NewTheme /></ProtectedRoute> : <Login />} />
                <Route path="/adminprofile" element={user ? <ProtectedRoute><AdminProfile /></ProtectedRoute> : <Login />} />
                <Route path="/dashboard" element={user ? <ProtectedRoute><Dashboard /></ProtectedRoute> : <Login />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App
