
function ProjeCard({ proje }) {
    return (
        <div className="bg-white p-6 rounded-md shadow-md" >
            <img
                src={proje.images[0].url}  // Proje resmi
                alt={proje?.title}
                className="w-full h-32 object-cover mb-4 rounded-md"
            />
            <h2 className="text-xl font-bold mb-2">{proje?.title}</h2>
            <p className="text-gray-700">{`${proje?.title}. Detaylar için...`}</p>
            <a href={`projects/${proje.id}`} className="text-red-500 font-bold mt-2 hover:underline">Detayları Gör</a>
        </div >
    )
}

export default ProjeCard