import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContrastIcon from '@mui/icons-material/Contrast';

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/adminSlice";
import DownloadJsonButton from "../../pages/users/Admin/DownloadJsonButton";

const Sidebar = () => {
  const dispatch = useDispatch();

  return (
    <div className="sidebar">
      <div className="mt-1 h-[50px] flex items-center justify-center">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="font-bold text-2xl text-[#6439ff]">Admin Paneli</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">Ana Sayfa</p>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/adminusers" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/adminslider" style={{ textDecoration: "none" }}>
            <li>
              <ImageSearchIcon className="icon" />
              <span>Slider</span>
            </li>
          </Link>
          <Link to="/adminprojeler" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Projeler</span>
            </li>
          </Link>
          <Link to="/adminreferanslar" style={{ textDecoration: "none" }}>
            <li>
              <ContactsIcon className="icon" />
              <span>Referanslar</span>
            </li>
          </Link>
          <Link to="/adminkurumsal" style={{ textDecoration: "none" }}>
            <li>
              <BusinessIcon className="icon" />
              <span>Kurumsal</span>
            </li>
          </Link>
          <Link to="/adminhakkinda" style={{ textDecoration: "none" }}>
            <li>
              <InfoIcon className="icon" />
              <span>Hakkında</span>
            </li>
          </Link>
          <Link to="/adminiletisim" style={{ textDecoration: "none" }}>
            <li>
              <ContactsIcon className="icon" />
              <span>İletişim</span>
            </li>
          </Link>
          <Link to="/adminkonum" style={{ textDecoration: "none" }}>
            <li>
              <LocationOnIcon className="icon" />
              <span>Konum</span>
            </li>
          </Link>
          <p className="title">USEFUL</p>
          <Link to="/adminnotification" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsNoneIcon className="icon" />
              <span>Notifications</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link to="/admintheme" style={{ textDecoration: "none" }}>
            <li>
              <ContrastIcon className="icon" />
              <span>Theme</span>
            </li>
          </Link>
          <Link to="/adminprofile" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <li>
            <CloudDownloadIcon className="icon" />
            <span> <DownloadJsonButton /></span>
          </li>
          <li onClick={() => dispatch(logout())}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
        // onClick={"LIGHT"}
        ></div>
        <div
          className="colorOption"
        // onClick={"DARK"}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
