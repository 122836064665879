import { Link } from 'react-router-dom';
import { references } from "../static/data";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Loader from './Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchReferanslar } from '../redux/referansSlice';
import { STATUS } from '../utils/status';

const References = () => {
    const dispatch = useDispatch();
    const { referanslardata, referanslarstatus, referanslarerror } = useSelector((state) => state.referans);
    useEffect(() => {
        if (referanslarstatus === STATUS.IDLE) {
            dispatch(fetchReferanslar());
        }
    }, [referanslarstatus, dispatch]);
    return (
        <div className="mx-auto justify-center items-center pb-2">
            <div className="text-xl sm:text-2xl md:text-3xl font-semibold ml-10 mt-2 mb-[-30px]">Referanslarımız</div>
            <Splide
                options={{
                    autoWidth: true,
                    rewind: true,
                    gap: "10px",
                }}
                aria-label="Referanslar"
            >
                {!references && (
                    <div className="h-[200px] flex items-center">
                        <Loader /> Error:{referanslarerror}
                    </div>
                )}
                {referanslardata?.map((d, i) => (
                    <SplideSlide key={i} className="relative">
                        <Link to={d.url} target='_blank' rel="noopener noreferrer">
                            <img
                                src={d.images[0].url}
                                alt={d.name}
                                height={100}
                                width={200}
                                className="h-full p-3 items-center justify-center cursor-pointer rounded bg-white object-cover"
                            />
                        </Link>
                    </SplideSlide>
                ))}
            </Splide>

        </div>
    );
};

export default References;