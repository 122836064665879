import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

// Timestamp dönüşümünü yapan fonksiyon
const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp && project.timeStamp.toDate ? project.timeStamp.toDate().toISOString() : null,
    };
};

const initialState = {
    corporatedata: [],
    corporatestatus: STATUS.IDLE,
    corporateerror: null,
};

export const fetchCorporate = createAsyncThunk('corporate/fetchCorporate', async () => {
    const querySnapshot = await getDocs(collection(db, 'corporate'));
    let corporate = [];
    querySnapshot.forEach((doc) => {
        corporate.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return corporate;
});

// Yeni bir proje oluşturmak için bir async thunk
export const addCorporate = createAsyncThunk('corporate/addCorporate', async ({ newCorporate }) => {
    const projectWithImages = { ...newCorporate, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, "corporate"), projectWithImages);
    return { id: docRef.id, ...projectWithImages };
});

// Proje silmek için bir async thunk
export const deleteCorporate = createAsyncThunk('corporate/deleteCorporate', async (id) => {
    await deleteDoc(doc(db, "corporate", id));
    return id;
});

// Proje güncellemek için bir async thunk
export const updateCorporate = createAsyncThunk('corporate/updateCorporate', async ({ id, updatedData }) => {
    const projectDoc = doc(db, "corporate", id);
    const projectWithImages = { ...updatedData, timeStamp: serverTimestamp() };
    await updateDoc(projectDoc, projectWithImages);
    return { id, updatedData: projectWithImages };
});

const corporateSlice = createSlice({
    name: 'corporate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCorporate.pending, (state) => {
                state.corporatestatus = STATUS.LOADING;
            })
            .addCase(fetchCorporate.fulfilled, (state, action) => {
                state.corporatestatus = STATUS.SUCCESS;
                state.corporatedata = action.payload;
            })
            .addCase(fetchCorporate.rejected, (state, action) => {
                state.corporatestatus = STATUS.FAIL;
                state.corporateerror = action.error.message;
            })
            .addCase(addCorporate.pending, (state) => {
                state.corporatestatus = STATUS.LOADING;
            })
            .addCase(addCorporate.fulfilled, (state, action) => {
                state.corporatestatus = STATUS.SUCCESS;
                state.corporatedata.push(action.payload);
            })
            .addCase(addCorporate.rejected, (state, action) => {
                state.corporatestatus = STATUS.FAIL;
                state.corporateerror = action.error.message;
            })
            .addCase(deleteCorporate.pending, (state) => {
                state.corporatestatus = STATUS.LOADING;
            })
            .addCase(deleteCorporate.fulfilled, (state, action) => {
                state.corporatestatus = STATUS.SUCCESS;
                state.corporatedata = state.corporatedata.filter(project => project.id !== action.payload);
            })
            .addCase(deleteCorporate.rejected, (state, action) => {
                state.corporatestatus = STATUS.FAIL;
                state.corporateerror = action.error.message;
            })
            .addCase(updateCorporate.pending, (state) => {
                state.corporatestatus = STATUS.LOADING;
            })
            .addCase(updateCorporate.fulfilled, (state, action) => {
                state.corporatestatus = STATUS.SUCCESS;
                const { id, updatedData } = action.payload;
                const existingProjectIndex = state.corporatedata.findIndex(project => project.id === id);
                if (existingProjectIndex !== -1) {
                    state.corporatedata[existingProjectIndex] = {
                        ...state.corporatedata[existingProjectIndex],
                        ...convertTimestampToDate(updatedData)
                    };
                }
            })
            .addCase(updateCorporate.rejected, (state, action) => {
                state.corporatestatus = STATUS.FAIL;
                state.corporateerror = action.error.message;
            });
    },
});

export default corporateSlice.reducer;
