import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import Users from "../../../components/List/UsersList";

const AdminUsers = () => {
    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="listContainer">
                    <Users title={"Kullanıcılar"} />
                </div>
            </div>
        </div>
    )
}

export default AdminUsers

