import { useSelector } from 'react-redux';

const DownloadJsonButton = () => {
    // Redux store'daki tüm slice'lardan verileri al
    const referansData = useSelector((state) => state.referans.referanslardata);
    const konumData = useSelector((state) => state.konum.konumdata);
    const communicationData = useSelector((state) => state.communication.iletisimdata);
    const aboutData = useSelector((state) => state.about.aboutdata);
    const corporateData = useSelector((state) => state.corporate.corporatedata);
    const projectData = useSelector((state) => state.project.projectdata);
    const usersData = useSelector((state) => state.users.usersdata);
    const sliderData = useSelector((state) => state.slider.sliderdata);
    const adminData = useSelector((state) => state.admin.admindata);

    const handleDownload = () => {
        // Tüm verileri birleştir
        const backupData = {
            referans: referansData,
            konum: konumData,
            communication: communicationData,
            about: aboutData,
            corporate: corporateData,
            project: projectData,
            users: usersData,
            slider: sliderData,
            admin: adminData,
        };

        const json = JSON.stringify(backupData, null, 2); // JSON verisi
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'redux_backup.json'; // İndirilen dosya adı
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div onClick={handleDownload}>
            Download JSON Backup
        </div>
    );
};

export default DownloadJsonButton;
