import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { STATUS } from "../../utils/status";
const Users = ({ title, error, data, status }) => {

    return (

        <>
            <div className="listTitle">{title}</div>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Name</TableCell>
                            <TableCell className="tableCell">Meslek</TableCell>
                            <TableCell className="tableCell">İş Tercübesi</TableCell>
                            <TableCell className="tableCell">Telefon</TableCell>
                            <TableCell className="tableCell">Yaş</TableCell>
                            <TableCell className="tableCell">Şehir</TableCell>
                            <TableCell className="tableCell">Mail</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={5} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {status === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={5} className="tableCell">Error: {error}</TableCell>
                            </TableRow>
                        )}
                        {status === STATUS.SUCCESS && data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    <div className="cellWrapper">
                                        <img src={row.images[0].url} alt="" className="image" />
                                        {row.displayName}
                                    </div>
                                </TableCell>
                                <TableCell className="tableCell">{row.job}</TableCell>
                                <TableCell className="tableCell">{row.jobyear}</TableCell>
                                <TableCell className="tableCell">{row.phone}</TableCell>
                                <TableCell className="tableCell">{row.age}</TableCell>
                                <TableCell className="tableCell">{row.country}</TableCell>
                                <TableCell className="tableCell">{row.email}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Users
