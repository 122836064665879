import React from 'react';
import { Link } from 'react-router-dom';
import BackgroundMusic from './BackgroundMusic';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';

const Footer = () => {
    const { themes } = useSelector((state) => state.theme);
    const defaultThemesFromState = themes.filter(theme => theme.isDefault);

    // Eğer defaultThemesFromState boşsa veya tanımsızsa, varsayılan değerleri kullanabiliriz
    const { background = '#111827', button = '#55acee', text = '#ffffff' } = defaultThemesFromState.length > 0 ? defaultThemesFromState[0] : {};

    return (
        <div className="bg-gray-900 text-white py-2 flex flex-col md:flex-row md:justify-between items-center" style={{ backgroundColor: background, color: text }}>
            <div className='h-[120px] w-[400px] flex justify-center md:mb-0'>
                <img
                    src={'https://res.cloudinary.com/dafvzct6j/image/upload/v1714995470/gurgenassets/kusptfwddwtmrtejfbhb.svg'}
                    alt="logo"
                    className="w-full h-full object-contain"
                />
            </div>
            <div className="flex justify-center mb-3">
                <BackgroundMusic />
            </div>

            <div className="text-center md:text-left mb-2 md:mb-0 md:flex-grow">
                <span className="block text-gray-400">© 2023 Gürgen İnşaat Elektrik. All rights reserved.</span>
            </div>

            <div className="flex justify-center space-x-2 mb-2 md:mb-0">
                <Link to={"/dashboard"} className="block">
                    <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: button }}
                        role='button'
                    >
                        <MDBIcon fas icon="columns" />
                    </MDBBtn>
                </Link>

                <MDBBtn
                    floating
                    className='m-1'
                    style={{ backgroundColor: '#dd4b39' }}
                    href='mailto:info@gurgenelektrik.com.tr'
                    role='button'
                >
                    <MDBIcon fab icon='google' />
                </MDBBtn>
                <MDBBtn
                    floating
                    className='m-1'
                    style={{ backgroundColor: '#0082ca' }}
                    href='mailto:info@gurgenelektrik.com.tr'
                    role='button'
                >
                    <MDBIcon fab icon='at' />
                </MDBBtn>
            </div>
        </div>
    );
};

export default Footer;
