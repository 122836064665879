import React, { useState, useEffect } from 'react';
import { carouselimg } from '../static/data';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSlider } from '../redux/sliderSlice';
import { STATUS } from '../utils/status';

const Carousel = () => {
    const dispatch = useDispatch();
    const [currentSlide, setCurrentSlide] = useState(0);
    const { sliderdata, sliderstatus } = useSelector((state) => state.slider);

    useEffect(() => {
        if (sliderstatus === STATUS.IDLE) {
            dispatch(fetchSlider());
        }
    }, [sliderstatus, dispatch]);
    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === carouselimg.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? carouselimg.length - 1 : prevSlide - 1));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000); // Otomatik geçiş her 5 saniyede bir

        return () => clearInterval(interval);
    }, [currentSlide]);

    // useEffect içerisindeki fonksiyonun doğru çalışması için bir değişiklik yaptım

    return (
        <div className="relative mt-0 mb-0 min-h-4/7 w-full">
            {/* Image Carousel */}
            <div className="relative overflow-hidden w-full h-4/7">
                {/* Slides */}
                <div
                    className="flex transition-transform duration-500 ease-in-out h-full"
                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                    {sliderdata.map((project) => (
                        <div key={project.id} className="w-full h-[60vh] flex-shrink-0 relative">
                            <div
                                style={{
                                    backgroundImage: `url(${project.images[0]})`,
                                    backgroundSize: '100% 100%', // 'cover' veya 'contain' da kullanabilirsin
                                    width: '100%',
                                    height: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                                className="absolute top-0 left-0 w-full h-full"
                            ></div>

                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white font-bold text-xl p-4">
                                {project.title}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Navigation Buttons */}
                <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
                    onClick={prevSlide}
                >
                    &#10094;
                </button>
                <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
                    onClick={nextSlide}
                >
                    &#10095;
                </button>
            </div>
        </div>
    );
};

export default Carousel;