import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../sidebar/Sidebar";
import Navbar from "../../navbar/Navbar";
import { addKonum } from "../../../redux/konumSlice";

const NewKonum = () => {
    const dispatch = useDispatch();
    const [konum, setKonum] = useState({
        firmaadi: "",
        url: "",
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setKonum({ ...konum, [id]: value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            // Veriyi Firebase Firestore'a gönder
            const userData = {
                ...konum,
            };

            await dispatch(addKonum({ newUser: userData }));

            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");

            // İşlem başarılı ise formu temizle
            setKonum({
                firmaadi: "",
                url: "",
            });

        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        }
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">New Konum</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Firma Adı</label>
                                <input
                                    id="firmaadi"
                                    type="text"
                                    placeholder="Enter Firma Adı"
                                    onChange={handleInput}
                                    value={konum.firmaadi}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="url">URL</label>
                                <input
                                    id="url"
                                    type="text"
                                    placeholder="Enter URL"
                                    onChange={handleInput}
                                    value={konum.url}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                                >
                                    Add Konum
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewKonum;