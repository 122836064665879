import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../utils/status';
import { fetchCorporate } from './../redux/corporateSlice';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import Carousel from './../components/Carousel';

const CorporatePage = () => {
    const dispatch = useDispatch();
    const { corporatedata, corporatestatus, corporateerror } = useSelector((state) => state.corporate);

    useEffect(() => {
        if (corporatestatus === STATUS.IDLE) {
            dispatch(fetchCorporate());
        }
    }, [corporatestatus, dispatch]);

    if (corporatestatus === STATUS.LOADING) {
        return <div className="h-[40vh] flex items-center"><Loader /></div>;
    }

    if (corporatestatus === STATUS.FAIL) {
        return <div className="text-center mt-4">Error: {toast.error(corporateerror)}</div>;
    }

    if (!corporatedata || corporatedata.length === 0) {
        return <div className="text-center mt-4">No corporate data available</div>;
    }

    const { degerler, hedefler, kalitepolitikasi, misyon, vizyon } = corporatedata[0];
    return (
        <div className="container mx-auto bg-white p-8 shadow-md rounded-md ">
            <h1 className="text-3xl font-bold mt-4 mb-4 text-center">Kurumsal Bilgiler</h1>

            <div className='rounded-2xl'>
                <Carousel />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8 justify-center m-4'>

                <div className="flex flex-col mb-3 rounded-xl border-spacing-1">
                    <h2 className="text-2xl font-bold mb-3 justify-center items-center">Misyon</h2>
                    <p className="text-gray-700 mb-6 flex-grow">{misyon}</p>
                    <img src="https://www.ckenerji.com.tr/tr/images/f10.png" alt="Misyon" className="mb-1 w-full" />
                </div>
                <div className="flex flex-col mb-3 rounded-xl border-spacing-1">
                    <h2 className="text-2xl font-bold mb-3 justify-center items-center">Vizyon</h2>
                    <p className="text-gray-700 mb-5 flex-grow">{vizyon}</p>
                    <img src="https://www.ckenerji.com.tr/tr/images/f11.png" alt="Vizyon" className="mb-1 w-full" />
                </div>
                <div className="flex flex-col mb-3 rounded-xl border-spacing-1">
                    <h2 className="text-2xl font-bold mb-3 justify-center items-center">Değerler</h2>
                    <ul className="list-disc list-inside text-gray-700 mb-4">
                        {degerler.map((data, i) => (<li key={i}>{data}</li>))}
                    </ul>
                    <img src="https://www.zaferdergisi.com/assets/images/content/495/degerler-egitimi-deyince_1520191115_thumb.jpg" alt="Değerler" className="mb-1 w-full" />
                </div>
                <div className="flex flex-col mb-3 rounded-xl border-spacing-1">
                    <h2 className="text-2xl font-bold mb-3 justify-center items-center">Hedefler</h2>
                    <ul className="list-disc list-inside text-gray-700 mb-4">
                        {hedefler.map((data, ind) => (<li key={ind}>{data}</li>))}
                    </ul>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeKevU1S-Pwymrd0h5vMG7mR0FQFFJGnIiVA&s" alt="Hedefler" className="mb-1 w-full" />

                </div>
                {/* <div className="flex flex-col mb-6">
                    <h2 className="text-2xl font-bold mb-3 justify-center items-center">Kalite Politikası</h2>
                    <p className="text-gray-700 mb-4">{kalitepolitikasi}</p>
                </div> */}
            </div>

            {/* <div className="flex flex-col mb-6">
                <h2 className="text-2xl font-bold mb-2">Şirket Tanıtım Videosu</h2>
                <video controls className="w-full h-auto">
                    <source src="path_to_video.mp4" type="video/mp4" />
                    Tarayıcınız video etiketini desteklemiyor.
                </video>
            </div> */}
        </div>
    );
}

export default CorporatePage;
