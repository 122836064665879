import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import Navbar from '../../navbar/Navbar';
import Sidebar from '../../sidebar/Sidebar';
import { toast } from 'react-toastify';
import { addTheme } from '../../../redux/themeSlice';
const ThemeManager = () => {
    const dispatch = useDispatch();
    const themes = useSelector(state => state.theme.themes);
    const [defaultThemes, setDefaultThemes] = useState([]);
    const [newTheme, setNewTheme] = useState({
        name: '',
        background: '',
        text: '',
        button: '',
        isDefault: false
    });

    useEffect(() => {
        const defaultThemesFromState = themes.filter(theme => theme.isDefault);
        setDefaultThemes(defaultThemesFromState);
    }, [themes]);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewTheme({
            ...newTheme,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleColorChange = (color, field) => {
        setNewTheme({
            ...newTheme,
            [field]: color.hex
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addTheme(newTheme)).then(() => {
            if (newTheme.isDefault) {
                setDefaultThemes([newTheme]); // Burayı değiştirildi.
            }
            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");
            setNewTheme({
                name: '',
                background: '',
                text: '',
                button: '',
                isDefault: false
            });
        }).catch(error => {
            console.error("Theme add error:", error);
            toast.error("Kayıt sırasında bir hata oluştu.");
        });
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">Add New Theme</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block mb-1 text-gray-700">Theme Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={newTheme.name}
                                    onChange={handleChange}
                                    placeholder="Theme Name"
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div className="flex space-x-4">
                                <div>
                                    <label className="block mb-1 text-gray-700">Background Color</label>
                                    <SketchPicker
                                        color={newTheme.background}
                                        onChangeComplete={(color) => handleColorChange(color, 'background')}
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 text-gray-700">Text Color</label>
                                    <SketchPicker
                                        color={newTheme.text}
                                        onChangeComplete={(color) => handleColorChange(color, 'text')}
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 text-gray-700">Button Color</label>
                                    <SketchPicker
                                        color={newTheme.button}
                                        onChangeComplete={(color) => handleColorChange(color, 'button')}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="isDefault"
                                    checked={newTheme.isDefault}
                                    onChange={handleChange}
                                    className="mr-2"
                                />
                                <label className="text-gray-700">Default Theme</label>
                            </div>
                            <div className="mt-4">
                                <button type="submit" className="p-2 bg-blue-500 text-white rounded">
                                    Add Theme
                                </button>
                            </div>
                        </form>
                        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl mt-5">
                            <h3 className="text-xl font-bold">Preview</h3>
                            <div
                                className="mt-4 p-4 rounded"
                                style={{
                                    backgroundColor: newTheme.background,
                                    color: newTheme.text
                                }}
                            >
                                <p>This is a text preview</p>
                                <button
                                    className="mt-2 p-2 rounded"
                                    style={{
                                        backgroundColor: newTheme.button,
                                        color: newTheme.text
                                    }}
                                >
                                    Button Preview
                                </button>
                            </div>
                        </div>
                        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl mt-5">
                            <h3 className="text-xl font-bold">Current Themes</h3>
                            {defaultThemes.length > 0 && (
                                <div
                                    className="mt-4 p-4 rounded"
                                    style={{
                                        backgroundColor: defaultThemes[0].background,
                                        color: defaultThemes[0].text,
                                    }}
                                >
                                    <p>This is a text preview</p>
                                    <button
                                        className="mt-2 p-2 rounded"
                                        style={{
                                            backgroundColor: defaultThemes[0].button,
                                            color: defaultThemes[0].text,
                                        }}
                                    >
                                        Button Preview
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThemeManager;
