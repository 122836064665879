
const ContactusPage = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        // Burada e-posta gönderme işlemi yapılabilir.
        // Ancak, e-posta gönderme işlemleri genellikle sunucu tarafında yapılır.
        console.log('Form gönderildi!');
    };
    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className="container mx-auto">
                <div className="max-w-2xl mx-auto bg-white p-8 shadow-md rounded-md">
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl  font-bold mb-6">İletişim</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-600 mb-2">
                                Adınız
                            </label>
                            <input type="text" id="name" name="name" className="w-full border p-2 rounded-md" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-600 mb-2">
                                E-posta Adresiniz
                            </label>
                            <input type="email" id="email" name="email" className="w-full border p-2 rounded-md" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-gray-600 mb-2">
                                Mesajınız
                            </label>
                            <textarea id="message" name="message" rows="4" className="w-full border p-2 rounded-md"></textarea>
                        </div>
                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">
                            Gönder
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactusPage
