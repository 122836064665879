import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProjeCard from '../components/ProjeCard';
import { fetchProjects } from '../redux/projectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../utils/status';

const Project = () => {
    const dispatch = useDispatch();
    const [projects, setProjects] = useState([]);
    const { projectdata, projectstatus, projecterror } = useSelector((state) => state.project);
    const location = useLocation();

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    useEffect(() => {
        if (projectstatus === STATUS.SUCCESS) {
            setProjects(projectdata);
        }
    }, [projectstatus, projectdata]);

    const query = new URLSearchParams(location.search);
    const filter = query.get('filter');

    const filteredTamam = projects.filter(project => project.status.toLowerCase() === "tamamlandı");
    const filteredDevamEdiyor = projects.filter(project => project.status.toLowerCase() === "devam ediyor");

    return (
        <div className="container mx-auto my-10">
            {filter ? (
                <>
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-4 mt-6">
                        {filter === 'tamamlanan' ? "Tamamlanan Projelerimiz" : "Devam Eden Projelerimiz"}
                    </h1>
                    {filter === 'tamamlanan' ? (
                        filteredTamam.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                {filteredTamam.map((e, i) => (<ProjeCard proje={e} key={i} />))}
                            </div>
                        ) : (
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-xl 2xl:text-2xl 3xl:text-2xl font-normal mb-4 mt-6">
                                Bu kategoride proje bulunmamaktadır.
                            </p>
                        )
                    ) : (
                        filteredDevamEdiyor.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                {filteredDevamEdiyor.map((e, i) => (<ProjeCard proje={e} key={i} />))}
                            </div>
                        ) : (
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-xl 2xl:text-2xl 3xl:text-2xl font-normal mb-4 mt-6">
                                Bu kategoride proje bulunmamaktadır.
                            </p>
                        )
                    )}
                </>
            ) : (
                <>
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-4 mt-6">Devam Eden Projelerimiz</h1>
                    {filteredDevamEdiyor.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {filteredDevamEdiyor.map((e, i) => (<ProjeCard proje={e} key={i} />))}
                        </div>
                    ) : (
                        <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-xl 2xl:text-2xl 3xl:text-2xl font-normal mb-4 mt-6">
                            Bu kategoride proje bulunmamaktadır.
                        </p>
                    )}

                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-4 mt-6">Tamamlanan Projelerimiz</h1>
                    {filteredTamam.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {filteredTamam.map((e, i) => (<ProjeCard proje={e} key={i} />))}
                        </div>
                    ) : (
                        <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-xl 2xl:text-2xl 3xl:text-2xl font-normal mb-4 mt-6">
                            Bu kategoride proje bulunmamaktadır.
                        </p>
                    )}
                </>
            )}
        </div>
    );
}

export default Project;
