import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadImage } from "../../../utils/uploadImage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../sidebar/Sidebar";
import Navbar from "../../navbar/Navbar";
import { addReferanslar } from "../../../redux/referansSlice";

const NewReferanslar = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [progress, setProgress] = useState({});
    const [referanslar, setReferanslar] = useState({
        name: "",
        url: "",
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setReferanslar({ ...referanslar, [id]: value });
    };

    const handleFilesChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);

        // Create previews for the selected files
        const selectedPreviews = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviews(selectedPreviews);
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            // Resimleri sıkıştır ve yükle
            const imageUploadResults = await Promise.all(files.map(file =>
                uploadImage(file, (progress) => {
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress
                    }));
                })
            ));

            // Dosya adlarını ve URL'lerini ayrı olarak al
            const imagePaths = imageUploadResults.map((url, index) => ({
                url: url.downloadURL,
                urlname: url.newFileName,
                path: `assets/referanslar/${url.newFileName}`, // Boşlukları kaldır ve '-' ile değiştir
            }));

            // Veriyi Firebase Firestore'a gönder
            const userData = {
                ...referanslar,
                images: imagePaths,
            };

            await dispatch(addReferanslar({ newUser: userData }));

            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");

            // İşlem başarılı ise formu temizle
            setReferanslar({
                name: "",
                url: "",
            });
            setFiles([]); // Dosya seçim alanını temizle
            setPreviews([]); // Önizleme alanını temizle
            setProgress({}); // Yükleme ilerlemesini temizle

        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        }
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">New Referanslar</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label htmlFor="files" className="block text-gray-700 font-medium mb-2">
                                    Firma Logosu
                                    <DriveFolderUploadOutlinedIcon className="ml-2 text-gray-500" />
                                </label>
                                <input
                                    type="file"
                                    id="files"
                                    multiple
                                    onChange={handleFilesChange}
                                    className="hidden"
                                />
                                <label htmlFor="files" className="cursor-pointer p-2 bg-blue-500 text-white rounded-md inline-block">Upload Images</label>
                                <div className="flex flex-wrap mt-2 gap-2">
                                    {previews.map((preview, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="w-24 h-24 object-cover border border-gray-300 rounded-lg"
                                            />
                                            <div className="absolute bottom-0 left-0 w-full bg-gray-700 bg-opacity-50 text-white text-xs text-center rounded-b-lg">
                                                {progress[files[index].name] ? `${Math.round(progress[files[index].name])}%` : '0%'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="title">Firma Adı</label>
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={handleInput}
                                    value={referanslar.name}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="title">Firma Site Url</label>
                                <input
                                    id="url"
                                    type="text"
                                    placeholder="Enter Url"
                                    onChange={handleInput}
                                    value={referanslar.url}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                                >
                                    Add Referanslar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewReferanslar;
