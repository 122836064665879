import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadImage } from '../utils/uploadImage';
import { addUsers } from '../redux/usersSlice';
import { toast } from 'react-toastify';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

const NewJob = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [progress, setProgress] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isKvkkAccepted, setIsKvkkAccepted] = useState(false);
    const [user, setUser] = useState({
        displayName: "",
        job: "",
        jobyear: "",
        phone: "",
        age: "",
        country: "",
        email: "",
        address: "",
        kvkk: false,  // KVKK onayı için yeni alan
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value });
    };

    const handleFilesChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);

        const selectedPreviews = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviews(selectedPreviews);
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        if (!isKvkkAccepted) {
            toast.error("Lütfen KVKK onayını kabul ediniz.");
            return;
        }
        setIsLoading(true);
        try {
            const imageUploadResults = await Promise.all(files.map(file =>
                uploadImage(file, (progress) => {
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress
                    }));
                })
            ));

            const imagePaths = imageUploadResults.map((url, index) => ({
                url: url.downloadURL,
                urlname: url.newFileName,
                path: `assets/users/${url.newFileName}`,
            }));

            const userData = {
                ...user,
                images: imagePaths,
            };

            await dispatch(addUsers({ newUser: userData }));

            toast.success("Form Başarılı Şekilde Gönderilmiştir...");

            setUser({
                displayName: "",
                job: "",
                jobyear: "",
                phone: "",
                age: "",
                country: "",
                email: "",
                address: "",
                kvkk: false,
            });
            setFiles([]);
            setPreviews([]);
            setProgress({});
        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard">
            <div className="dashboardContainer">
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-4xl font-bold mt-6 mb-3">İş Başvurusu</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label htmlFor="files" className="block text-gray-700 font-medium mb-2">
                                    Fotoğraf Yükleyiniz
                                    <DriveFolderUploadOutlinedIcon className="ml-2 text-gray-500" />
                                </label>
                                <input
                                    type="file"
                                    id="files"
                                    multiple
                                    onChange={handleFilesChange}
                                    className="hidden"
                                    placeholder="Sizi Tanıtan Bir Resim Giriniz..."
                                />
                                <label htmlFor="files" className="cursor-pointer p-2 bg-blue-500 text-white rounded-md inline-block">Resmi Yükle</label>
                                <div className="flex flex-wrap mt-2 gap-2">
                                    {previews.map((preview, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="w-24 h-24 object-cover border border-gray-300 rounded-lg"
                                            />
                                            <div className="absolute bottom-0 left-0 w-full bg-gray-700 bg-opacity-50 text-white text-xs text-center rounded-b-lg">
                                                {progress[files[index].name] ? `${Math.round(progress[files[index].name])}%` : '0%'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="displayName">İsim Soyisim</label>
                                <input
                                    id="displayName"
                                    type="text"
                                    placeholder="İsim Soyad giriniz..."
                                    onChange={handleInput}
                                    value={user.displayName}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="job">Meslek</label>
                                <input
                                    id="job"
                                    type="text"
                                    placeholder="Meslek Giriniz...[Elektrikçi, Mühendis vs.]"
                                    onChange={handleInput}
                                    value={user.job}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="jobyear">Mesleki Tercübe</label>
                                <input
                                    id="jobyear"
                                    placeholder="Meslekte Toplam Çalışma Yılınızı Giriniz..."
                                    onChange={handleInput}
                                    value={user.jobyear}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">Telefon Numarası</label>
                                <input
                                    id="phone"
                                    type="text"
                                    placeholder="Sizinle İletişim Kurulacak Telefon Numarasını Giriniz."
                                    onChange={handleInput}
                                    value={user.phone}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="age">Yaş</label>
                                <input
                                    id="age"
                                    type="text"
                                    placeholder="Yaşınızı Giriniz.."
                                    onChange={handleInput}
                                    value={user.age}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="country">Şehir</label>
                                <input
                                    id="country"
                                    type="text"
                                    placeholder="Yaşadığınız Şehiri Giriniz..."
                                    onChange={handleInput}
                                    value={user.country}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    type="text"
                                    placeholder="Varsa Mail Adresinizi Giriniz..."
                                    onChange={handleInput}
                                    value={user.email}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="address">Address</label>
                                <input
                                    id="address"
                                    type="text"
                                    placeholder="Address Giriniz..."
                                    onChange={handleInput}
                                    value={user.address}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <Link href="/privacy" target="_blank" rel="noopener noreferrer">KVKK Metni</Link>
                            </div>
                            <div className="mb-4 flex items-center">
                                <input
                                    type="checkbox"
                                    id="kvkk"
                                    checked={isKvkkAccepted}
                                    onChange={() => {
                                        setIsKvkkAccepted(!isKvkkAccepted);
                                        setUser({ ...user, kvkk: !isKvkkAccepted });
                                    }}
                                    className="mr-2"
                                />
                                <label htmlFor="kvkk" className="text-gray-700">KVKK onayını kabul ediyorum.</label>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    disabled={!isKvkkAccepted || isLoading}
                                    className={`px-4 py-2 ${isKvkkAccepted ? 'bg-blue-500' : 'bg-gray-500'} text-white rounded-md`}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : "CV Gönder"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewJob;
