import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../utils/status";
import { fetchCommunication } from "../redux/iletisimSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { fetchKonum } from "../redux/konumSlice";
import Iframe from "react-iframe";

const CommunicationPage = () => {
    const dispatch = useDispatch();
    const { iletisimdata, iletisimstatus, iletisimerror } = useSelector((state) => state.communication);
    const { konumdata, konumstatus, konumerror } = useSelector((state) => state.konum);
    useEffect(() => {
        if (iletisimstatus === STATUS.IDLE) {
            dispatch(fetchCommunication());
        }
        if (konumstatus === STATUS.IDLE) {
            dispatch(fetchKonum());
        }
    }, [iletisimstatus, konumstatus, dispatch]);

    // Yüklenme durumunu kontrol etme
    if (iletisimstatus === STATUS.LOADING) {
        return <div className="h-[40vh] flex items-center">  <Loader />    </div>
    }

    // Hata durumunu kontrol etme
    if (iletisimstatus === STATUS.FAIL) {
        return <div className="text-center mt-4">Error: {toast.error(iletisimerror)}</div>;
    }

    // Veri boşsa kontrol etme
    if (!iletisimdata || iletisimdata.length === 0) {
        return <div className="text-center mt-4">No location data available</div>;
    }

    // Konum verisi mevcutsa bileşeni render etme
    const { adres, email, firmaadi, images, telefon } = iletisimdata[0];
    // Yüklenme durumunu kontrol etme
    if (konumstatus === STATUS.LOADING) {
        return <div className="h-[40vh] flex items-center">  <Loader />    </div>
    }

    // Hata durumunu kontrol etme
    if (konumstatus === STATUS.FAIL) {
        return <div className="text-center mt-4">Error: {toast.error(konumerror)}</div>;
    }

    // Veri boşsa kontrol etme
    if (!konumdata || konumdata.length === 0) {
        return <div className="text-center mt-4">No location data available</div>;
    }

    // Konum verisi mevcutsa bileşeni render etme
    const { url } = konumdata[0];
    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className="container mx-auto">
                <div className="mx-auto bg-white p-4 shadow-md rounded-md">
                    <div className="container mx-auto text-center mb-3 mt-3">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl 3xl:text-4xl m-3 font-bold">{firmaadi}</h1>
                        <div className="w-full h-[70vh] mx-auto">
                            <Iframe
                                url={url}
                                width="100%"
                                height="100%"
                                loading="lazy"
                                title="Firma Konumu"
                            />
                        </div>
                    </div>
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-5 mt-8">İletişim Bilgileri</h1>
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold mb-2">Firma Adı</h2>
                        <p>{firmaadi}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold mb-2">Adres</h2>
                        <p>{adres}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold mb-2">Telefon</h2>
                        <p>{telefon}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold mb-2">E-posta</h2>
                        <p>{email}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunicationPage
