import { useParams } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Loader from '../components/Loader';
import { useEffect, useState } from 'react';
import { fetchProjects } from '../redux/projectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../utils/status';

const ProjectsDetailPage = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { projectdata, projectstatus } = useSelector((state) => state.project);
    const [project, setProject] = useState(null);

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    useEffect(() => {
        if (projectstatus === STATUS.SUCCESS) {
            const selectedProject = projectdata.find(project => project.id === projectId);
            setProject(selectedProject);
        }
    }, [projectstatus, projectdata, projectId]);

    if (!project) {
        return (
            <div className="container mt-10">
                <Loader />
            </div>
        );
    }

    return (
        <div className="container mt-10">
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold mb-4">{project?.title}</h1>

            <Splide
                options={{
                    perPage: 1,
                    heightRatio: '70vh', // Ekran yüksekliğinin yarısı kadar
                    gap: 10,
                    rewind: true,
                }}
            >
                {!project.images && (
                    <div className="h-[50vh] flex items-center justify-center">
                        <Loader />
                    </div>
                )}
                {project.images?.map((img, i) => (
                    <SplideSlide key={i}>
                        <div className="flex items-center justify-center">
                            <img
                                className="w-[100vh] h-[70vh] object-cover rounded"
                                src={img.url}
                                alt=""
                            />
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
            <div className="bg-white pb-6 pl-6 pr-6 rounded-md shadow-md">
                <p className='font-bold text-xl'>{project?.grup === "özel" ? " Firma" : " Kurum"} Adı: <span className='font-normal'>{project?.company}</span></p>
                {project?.isveren && <p className='font-bold text-xl'>Yüklenici Firma Adı: <span className='font-normal'>{project?.isveren}</span></p>}
                <p className='font-bold text-xl'>Projenin Yapıldığı Şehir: <span className='font-normal'>{project?.sehir}</span></p>
                <p className='font-bold text-xl'>Projenin Durumu: <span className='font-normal'>{project?.status}</span></p>
                <p className='font-bold text-xl'>Projenin Yapım Yılı: <span className='font-normal'>{project?.year}</span></p>
                {project?.kapasite && (
                    <p className='font-bold text-xl'>
                        {project?.company.toLowerCase().includes("yurtlar") || project?.title.toLowerCase().includes("hastane") ? "Yurt Kapasitesi: " : "Projenin Daire Sayısı: "}
                        <span className='font-normal'>{project?.kapasite}</span>
                    </p>
                )}
                <div className='text-2xl font-bold mt-6 mb-3'>Proje Kapsamında Yapılan İşler</div>
                <p className="text-gray-700 mt-1 mb-3" dangerouslySetInnerHTML={{ __html: project?.description }} />
            </div>
        </div >
    );
};

export default ProjectsDetailPage;
