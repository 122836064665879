
import Carousel from './../../components/Carousel';

import ImageSlider from './../../components/ImageSlider';
import References from './../../components/References';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../../redux/projectSlice';
import { useEffect, useState } from 'react';
import { STATUS } from '../../utils/status';
import WorkWithUs from '../WorkWithUs';
const Home = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const { projectdata, projectstatus, projecterror } = useSelector((state) => state.project);
    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    useEffect(() => {
        if (projectstatus === STATUS.SUCCESS) {
            setData(projectdata);
        }
    }, [projectstatus, projectdata]);
    const filteredTamam = projectdata.filter(project => project.status.toLowerCase() === "tamamlandı");
    const filtereddevamediyor = projectdata.filter(project => project.status.toLowerCase() === "devam ediyor");
    return (
        <div className="bg-gray-200">
            <Carousel />
            <ImageSlider data={filteredTamam} title="Tamamlanan Projelerimiz" />
            <ImageSlider data={filtereddevamediyor} title="Devam Eden Projelerimiz" />
            <References />
            {/* <WorkWithUs /> */}
        </div>
    )
}

export default Home