import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../utils/status';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SketchPicker } from 'react-color';
import { deleteTheme, fetchThemes, updateTheme } from '../../redux/themeSlice';

const ThemeList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingTheme, setEditingTheme] = useState(null);
    const [editedTheme, setEditedTheme] = useState({});

    const { themes, status, error, currentTheme } = useSelector((state) => state.theme);

    useEffect(() => {
        if (status === STATUS.IDLE) {
            dispatch(fetchThemes());
        }
    }, [status, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteTheme(id));
    };

    const handleUpdate = (id) => {
        dispatch(updateTheme({ id, updatedData: editedTheme }));
        setEditingTheme(null);
        setEditedTheme({});
    };

    const handleEdit = (theme) => {
        setEditingTheme(theme.id);
        setEditedTheme({ ...theme });
    };

    const handleCancel = () => {
        setEditingTheme(null);
        setEditedTheme({});
    };

    const handleColorChange = (color, field) => {
        setEditedTheme({
            ...editedTheme,
            [field]: color.hex
        });
    };

    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-theme" className="mb-2 inline-block">
                <button className="btn btn-primary">New Theme</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Name</TableCell>
                            <TableCell className="tableCell">Background</TableCell>
                            <TableCell className="tableCell">Button</TableCell>
                            <TableCell className="tableCell">Default</TableCell>
                            <TableCell className="tableCell">Text</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={7} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {status === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={7} className="tableCell">Error: {error}</TableCell>
                            </TableRow>
                        )}
                        {status === STATUS.SUCCESS && themes.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingTheme === row.id ? (
                                        <input
                                            type="text"
                                            value={editedTheme.name}
                                            onChange={(e) => setEditedTheme({ ...editedTheme, name: e.target.value })}
                                            placeholder="Edit Name"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.name
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div style={{ backgroundColor: row.background, width: '50px', height: '20px' }}></div>
                                    {editingTheme === row.id && (
                                        <SketchPicker
                                            color={editedTheme.background}
                                            onChangeComplete={(color) => handleColorChange(color, 'background')}
                                        />
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div style={{ backgroundColor: row.button, width: '50px', height: '20px' }}></div>
                                    {editingTheme === row.id && (
                                        <SketchPicker
                                            color={editedTheme.button}
                                            onChangeComplete={(color) => handleColorChange(color, 'button')}
                                        />
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingTheme === row.id ? (
                                        <input
                                            type="checkbox"
                                            checked={editedTheme.isDefault}
                                            onChange={(e) => setEditedTheme({ ...editedTheme, isDefault: e.target.checked })}
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.isDefault ? 'Yes' : 'No'
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div style={{ backgroundColor: row.text, width: '50px', height: '20px' }}></div>
                                    {editingTheme === row.id && (
                                        <SketchPicker
                                            color={editedTheme.text}
                                            onChangeComplete={(color) => handleColorChange(color, 'text')}
                                        />
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingTheme === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ThemeList;

