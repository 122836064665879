import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../sidebar/Sidebar";
import Navbar from "../../navbar/Navbar";
import { addCorporate } from "../../../redux/corporateSlice"
const NewKurumsal = () => {
    const dispatch = useDispatch();
    const [kurumsal, setKurumsal] = useState({
        degerler: [],
        hedefler: [],
        kalitepolitikasi: "",
        misyon: "",
        vizyon: "",
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setKurumsal({ ...kurumsal, [id]: value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            // "degerler" ve "hedefler" alanlarını diziye dönüştür
            const degerlerArray = kurumsal.degerler.split(", ").map(item => item.trim());
            const hedeflerArray = kurumsal.hedefler.split(", ").map(item => item.trim());
            // Veriyi Firebase Firestore'a gönder
            const userData = {
                ...kurumsal,
                degerler: degerlerArray,
                hedefler: hedeflerArray,
            };

            await dispatch(addCorporate({ newCorporate: userData }));

            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");

            // İşlem başarılı ise formu temizle
            setKurumsal({
                degerler: [],
                hedefler: [],
                kalitepolitikasi: "",
                misyon: "",
                vizyon: "",
            });

        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        }
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">New Kurumsal</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Değerler</label>
                                <input
                                    id="degerler"
                                    type="text"
                                    placeholder="Degerleri Virgül Boşluk Bırakarak Yazınız"
                                    onChange={handleInput}
                                    value={kurumsal.degerler}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Hedefler</label>
                                <input
                                    id="hedefler"
                                    type="text"
                                    placeholder="Hedefleri Virgül Boşluk Bırakarak Yazınız"
                                    onChange={handleInput}
                                    value={kurumsal.hedefler}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Kalite Politikası</label>
                                <input
                                    id="kalitepolitikasi"
                                    type="text"
                                    placeholder="Kalite Politikasını Yazınız"
                                    onChange={handleInput}
                                    value={kurumsal.kalitepolitikasi}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Misyon</label>
                                <input
                                    id="misyon"
                                    type="text"
                                    placeholder="Misyonu Yazınız"
                                    onChange={handleInput}
                                    value={kurumsal.misyon}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="firmaadi">Vizyon</label>
                                <input
                                    id="vizyon"
                                    type="text"
                                    placeholder="Vizyonu Yazınız"
                                    onChange={handleInput}
                                    value={kurumsal.vizyon}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                                >
                                    Add Kurumsal
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewKurumsal;