import Sidebar from '../../../components/sidebar/Sidebar'
import Navbar from '../../../components/navbar/Navbar'
import Kurumsal from "../../../components/List/KurumsalList.jsx";

const AdminKurumsal = () => {
    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="listContainer">
                    <Kurumsal title={"Kurumsal"} />
                </div>
            </div>
        </div>
    )
}

export default AdminKurumsal
