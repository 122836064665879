import Iframe from 'react-iframe';
import { fetchKonum } from './../redux/konumSlice';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../utils/status';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const LocationPage = () => {
    const dispatch = useDispatch();
    const { konumdata, konumstatus, konumerror } = useSelector((state) => state.konum);

    useEffect(() => {
        if (konumstatus === STATUS.IDLE) {
            dispatch(fetchKonum());
        }
    }, [konumstatus, dispatch]);

    // Yüklenme durumunu kontrol etme
    if (konumstatus === STATUS.LOADING) {
        return <div className="h-[40vh] flex items-center">  <Loader />    </div>
    }

    // Hata durumunu kontrol etme
    if (konumstatus === STATUS.FAIL) {
        return <div className="text-center mt-4">Error: {toast.error(konumerror)}</div>;
    }

    // Veri boşsa kontrol etme
    if (!konumdata || konumdata.length === 0) {
        return <div className="text-center mt-4">No location data available</div>;
    }

    // Konum verisi mevcutsa bileşeni render etme
    const { firmaadi, url } = konumdata[0];

    return (
        <div className="container mx-auto text-center mb-5 mt-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl 3xl:text-4xl m-3 font-bold">{firmaadi}</h1>
            <div className="w-full h-[70vh] mx-auto">
                <Iframe
                    url={url}
                    width="100%"
                    height="100%"
                    loading="lazy"
                    title="Firma Konumu"
                />
            </div>
        </div>
    );
}

export default LocationPage;
