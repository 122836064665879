import { Button, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { IoSend } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import registerImage from "../../assets/images/register-image.jpg";
import { formSchema } from "../../components/register/formSchema";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginUserWithGoogle } from "../../redux/adminSlice";
import { STATUS } from "../../utils/status";
import { useEffect } from "react";

const Login = () => {
    const dispatch = useDispatch();
    const { status, error, user } = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const formState = useForm({
        initialValues: {
            email: "",
            password: "",
        },
        validate: zodResolver(formSchema),
    });

    useEffect(() => {
        if (user) {
            navigate('/dashboard');
        }
        if (error) {
            toast.error(error);
        }
    }, [user, navigate, error]);

    const handleGoogleLogin = () => {
        dispatch(loginUserWithGoogle());
    };
    const authFunc = async (values) => {
        try {
            if (status === STATUS.IDLE) {
                dispatch(loginUser(values)).unwrap()
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <div className="w-full text-dark h-screen bg-slate-100 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="hidden md:block bg-slate-300">
                <img
                    src={registerImage}
                    alt="Resim"
                    className="w-full max-h-screen min-h-screen object-cover"
                />
            </div>
            <div className="bg-slate-100 flex justify-center items-center flex-col">
                <h1 className="text-3xl pb-5 text-rose-500">Giriş Yap</h1>
                <div className="w-[75%] sm:w-[60%] md:w-[40%]">
                    <form
                        onSubmit={formState.onSubmit(authFunc)}
                        className="w-full space-y-6"
                    >
                        <TextInput
                            placeholder="Lütfen Email giriniz"
                            id="email"
                            label="Email"
                            withAsterisk
                            {...formState.getInputProps("email")}
                        />
                        <TextInput
                            placeholder="Lütfen Şifrenizi giriniz"
                            id="password"
                            label="Şifreniz"
                            withAsterisk
                            type="password"
                            {...formState.getInputProps("password")}
                        />
                        <Button leftSection={<IoSend />} type="submit" fullWidth>Giriş Yapınız</Button>
                        <Button onClick={handleGoogleLogin} > Google ile giriş yap </Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
