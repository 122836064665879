import { z } from "zod";

export const formSchema = z.object({
    email: z
        .string({ message: "Lütfen Email adresinizi giriniz" })
        .email({ message: "Lütfen geçerli bir email adresi giriniz" }),
    password: z
        .string({ message: "Lütfen Şifrenizi giriniz" })
        .min(6, { message: "Şifreniz en az 6 karakterden oluşabilir" })
        .max(36, { message: "Şifreniz en fazla 36 karakterden oluşabilir" }),
});