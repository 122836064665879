import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { STATUS } from "../../utils/status";

const List = ({ title, error, data, status }) => {
  const statusClasses = {
    Tamamlandı: "bg-green-100 text-green-800",
    "Devam Ediyor": "bg-gray-100 text-gray-800",
    Onaylı: "bg-yellow-100 text-yellow-800",
  };


  return (
    <>
      <div className="listTitle mt-4 text-2xl">{title}</div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">ID</TableCell>
              <TableCell className="tableCell">Title</TableCell>
              <TableCell className="tableCell">Company</TableCell>
              <TableCell className="tableCell">Year</TableCell>
              <TableCell className="tableCell">Status</TableCell>
              <TableCell className="tableCell">Grup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === STATUS.LOADING && (
              <TableRow>
                <TableCell colSpan={5} className="tableCell">Loading...</TableCell>
              </TableRow>
            )}
            {status === STATUS.FAIL && (
              <TableRow>
                <TableCell colSpan={5} className="tableCell">Error: {error}</TableCell>
              </TableRow>
            )}
            {status === STATUS.SUCCESS && data.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="tableCell">{row.id}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img src={row.images[0].url} alt="" className="image" />
                    {row.title}
                  </div>
                </TableCell>
                <TableCell className="tableCell">{row.company}</TableCell>
                <TableCell className="tableCell">{row.year}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status py-1 px-2 rounded ${statusClasses[row.status] || 'bg-gray-100 text-gray-800'}`}>
                    {row.status}
                  </span>
                </TableCell>
                <TableCell className="tableCell">{row.grup}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
