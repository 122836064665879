import { useState } from 'react'

const Privacy = () => {
    const [activeTab, setActiveTab] = useState(0);

    const toggleTab = (tab) => {
        if (activeTab === tab) {
            setActiveTab(0);
        } else {
            setActiveTab(tab);
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className='container mx-auto bg-white p-4 shadow-md rounded-md'>
                <h2 className="text-3xl font-bold text-gray-900 mb-8">Gizlilik Politikası</h2>
                <div className="mx-auto space-y-4">
                    {/* single Faq */}

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(2)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel Veriler Kanunu hakkında genel bilgilendirme
                            </span>
                            {activeTab === 2 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 2 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    6698 Sayılı Kişisel Verilerin Korunması Kanunu (bundan sonra KVKK olarak anılacaktır) 24 Mart 2016 tarihinde kabul edilmiş, 7 Nisan 2016 tarihli 29677 sayılı Resmi Gazete’de yayınlanmıştır. KVKK’nın bir kısmı yayın tarihinde, bir kısmı ise 7 Ekim 2016’da yürürlüğe girmiştir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(3)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Veri sorumlusu sıfatıyla bilgilendirme
                            </span>
                            {activeTab === 3 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 3 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    6698 sayılı KVKK uyarınca ve Veri Sorumlusu sıfatıyla, kişisel verileriniz bu sayfada açıklandığı çerçevede; kaydedilecek, saklanacak, güncellenecek, mevzuatın izin verdiği durumlarda 3. kişilere açıklanabilecek / devredilebilecek, sınıflandırılabilecek ve KVKK’da sayılan şekillerde işlenebilecektir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(4)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerinizin ne şekilde işlenebileceği
                            </span>
                            {activeTab === 4 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 4 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    6698 sayılı KVKK uyarınca, Firmamız ile paylaştığınız kişisel verileriniz, tamamen veya kısmen,otomatik olarak veyahut herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek, yeniden düzenlenerek, kısacası veriler üzerinde gerçekleştirilen her türlü işleme konu olarak tarafımızdan işlenebilecektir. KVKK kapsamında veriler üzerinde gerçekleştirilen her türlü işlem "kişisel verilerin işlenmesi” olarak kabul edilmektedir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(5)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri
                            </span>
                            {activeTab === 5 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 5 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    Paylaştığınız kişisel veriler şu amaçlarla işlenecektir:

                                    <ul className="list-disc pl-6">
                                        <li>
                                            Müşterilere verilen hizmetlerin gereklerini, sözleşmenin ve teknolojinin gereksinimlerine uygun bir şekilde yapabilmek, sunulan ürün ve hizmetleri geliştirmek için.
                                        </li>
                                        <li>
                                            6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve bu düzenlemelere dayanak yapılarak hazırlanan 26.08.2015 tarihli 29457 sayılı RG’de yayınlanan Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik, 27.11.2014 tarihli ve 29188 sayılı RG’de yayınlanan Mesafeli Sözleşmeler Yönetmeliği ve diğer ilgili mevzuat kapsamında işlem sahibinin bilgilerini tespit etmek için kimlik, adres ve diğer gerekli bilgileri kaydetmek.
                                        </li>
                                        <li>
                                            Bankacılık ve Elektronik Ödeme alanında zorunlu olan ödeme sistemleri, elektronik sözleşme veya kâğıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek; mevzuat gereği ve diğer otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak için.
                                        </li>
                                        <li>
                                            Kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu görevlilerine bilgi verebilmek için.
                                        </li>
                                    </ul>
                                </p>
                                <p className="text-base text-gray-500">
                                    Kişisel verileriniz, 6698 sayılı KVKK ve ilgili ikincil düzenlemelere uygun olarak işlenecektir. Bu kapsamda, paylaştığınız kişisel verilerinizin aktarılabileceği üçüncü kişi veya kuruluşlar şunlardır:
                                </p>
                                <ul className="list-disc pl-6">
                                    <li>
                                        Tedarikçiler, kargo şirketleri gibi sunulan hizmetler ile ilgili kişi ve kuruluşlar.
                                    </li>
                                    <li>
                                        Faaliyetlerimizi yürütmek üzere ve/veya Veri İşleyen sıfatı ile hizmet alınan, iş birliği yaptığımız program ortağı kuruluşları.
                                    </li>
                                    <li>
                                        Yurtiçi / yurtdışı kuruluşlar ve diğer 3. kişiler.
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(6)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerinizin toplanma şekli
                            </span>
                            {activeTab === 6 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 6 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    Kişisel verileriniz, şu şekillerde toplanabilir ve işlenebilir:

                                    <ul className="list-disc pl-6">
                                        <li>
                                            Firmamız internet sitesin hiç bir bilgi yada diğer şeyleri kayıt etmemektedir ve uygulamalarındaki formlar aracılığıyla ad, soyad, adres, telefon, iş veya özel e-posta adresi gibi bilgiler sadece form aracılığı ile kişi rızası ile mail şeklinde tutmaktadır.
                                        </li>
                                        <li>
                                            Firmamız ile ticari ilişki kurmak, iş başvurusu yapmak, teklif vermek gibi amaçlarla, kartvizit, özgeçmiş (cv), teklif vermek ve sair yollarla kişisel verilerini paylaşan kişilerden alınan, fiziki veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya yazılı ya da elektronik ortamdan.
                                        </li>
                                        <li>
                                            Ayrıca farklı kanallardan dolaylı yoldan elde edilen, web sitesi, blog, yarışma, anket, oyun, kampanya ve benzeri amaçlı kullanılan (mikro) web sitelerinden ve sosyal medyadan elde edilen veriler, e-bülten okuma veya tıklama hareketleri, kamuya açık veri tabanlarının sunduğu veriler, sosyal medya platformlarından paylaşıma açık profil ve verilerden; işlenebilmekte ve toplanabilmektedir.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(7)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerinizin yurt dışına aktarılması
                            </span>
                            {activeTab === 7 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 7 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    Hiç bir veri Tutulmadığında veriler yine tutulması durumunda Kvkk ya uygun şekilde, Türkiye’de işlenerek veya Türkiye dışında işlenip muhafaza edilmek üzere, yukarıda sayılan yöntemlerden herhangi birisi ile toplanmış kişisel verileriniz KVKK kapsamında kalmak kaydıyla ve sözleşme amaçlarına uygun olarak yurtdışında bulunan (Kişisel Veriler Kurulu tarafından akredite edilen ve kişisel verilerin korunması hususunda yeterli korumanın bulunduğu ülkelere) hizmet aracılarına da aktarılabilecektir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(8)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerin saklanması ve korunması
                            </span>
                            {activeTab === 8 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 8 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    Kişisel verileriniz, Firmamız nezdinde yer alan veri tabanında ve sistemlerde KVKK’nun 12. maddesi gereğince gizli olarak saklanacak; yasal zorunluluklar ve bu belgede belirtilen düzenlemeler haricinde hiçbir şekilde üçüncü kişilerle paylaşılmayacaktır. Firmamız, kişisel verilerinizin barındığı sistemleri ve veri tabanlarını, KVKK’nun 12. Maddesi gereği kişisel verilerin hukuka aykırı olarak işlenmesini önlemekle, yetkisiz kişilerin erişimlerini engellemekle, erişim yönetimi gibi yazılımsal tedbirleri ve fiziksel güvenlik önlemleri almakla mükelleftir. Kişisel verilerin yasal olmayan yollarla başkaları tarafından elde edilmesinin öğrenilmesi halinde durum derhal, yasal düzenlemeye uygun ve yazılı olarak Kişisel Verileri Koruma Kurulu’na bildirilecektir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(9)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                Kişisel verilerin güncel ve doğru tutulması
                            </span>
                            {activeTab === 9 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 9 && (
                            <div className="mt-4">
                                <p className="text-base text-gray-500">
                                    KVKK’nun 4. maddesi uyarınca Firmamızın kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Firmamızın yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için Müşterilerimizin doğru ve güncel verilerini paylaşması veya web sitesi / mobil uygulama üzerinden güncellemesi gerekmektedir.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="border-b border-gray-200 pb-4">
                        <button
                            className="flex items-center justify-between w-full"
                            onClick={() => toggleTab(10)}
                        >
                            <span className="text-lg font-medium text-gray-900">
                                6698 sayılı KVKK uyarınca kişisel veri sahibinin hakları
                            </span>
                            {activeTab === 10 ? (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            )}
                        </button>
                        {activeTab === 10 && (
                            <div>
                                <p>
                                    Sitemizde hiç bir veri tutulmadığında veya tutulması yükümlülüğü 6698 sayılı KVKK'nın 11. maddesi 07 Ekim 2016 tarihinde yürürlüğe girmiştir. İlgili madde gereğince, Kişisel Veri Sahibi’nin bu tarihten sonraki hakları aşağıdaki gibidir:
                                </p>

                                <ol className="list-decimal pl-6">
                                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,</li>
                                    <li>KVKK’nun 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                                    <li>Kişisel verilerin düzeltilmesi, silinmesi, yok edilmesi halinde bu işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere de bildirilmesini isteme,</li>
                                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                                    <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme.</li>
                                </ol>

                                <p>
                                    Gürgen İnşaat Elektrik sitesinde form ile cv gönderimi vs. alanlarda hiç bir veri tutulmadığında KVKK kapsamındaki sorumluluğu bulunmamaktadır.
                                </p>

                                <p className="text-blue-500">
                                    E-posta: <a href="mailto:info@elektrikcimnet.com">info@gurgenelektrik.com.tr</a>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy
