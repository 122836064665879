import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadImage } from "../../../utils/uploadImage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Navbar from "../../navbar/Navbar";
import Sidebar from "../../sidebar/Sidebar";
import { addAbout } from "../../../redux/aboutSlice";

const NewHakkinda = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [progress, setProgress] = useState({});
    const [data, setData] = useState({
        firmasahibi: '',
        tanitim: [],
        tanitim1: '',
        title: '',
    });

    const handleInput = (e) => {
        const { id, value } = e.target;
        setData({ ...data, [id]: value });
    };

    const handleFilesChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);

        // Create previews for the selected files
        const selectedPreviews = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviews(selectedPreviews);
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            // Resimleri sıkıştır ve yükle
            const imageUploadResults = await Promise.all(files.map(file =>
                uploadImage(file, (progress) => {
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress
                    }));
                })
            ));

            // Dosya adlarını ve URL'lerini ayrı olarak al
            const imagePaths = imageUploadResults.map((url, index) => ({
                url: url.downloadURL,
                urlname: url.newFileName,
                path: `assets/images/${url.newFileName}`, // Boşlukları kaldır ve '-' ile değiştir
            }));

            // "degerler" ve "hedefler" alanlarını diziye dönüştür
            const tanitimArray = data.tanitim.split(", ").map(item => item.trim());

            // Veriyi Firebase Firestore'a gönder
            const projectData = {
                ...data,
                tanitim: tanitimArray,
                images: imagePaths,
            };

            await dispatch(addAbout({ newProject: projectData }));

            toast.success("Kayıt Başarılı Şekilde Yapılmıştır...");

            // İşlem başarılı ise formu temizle
            setData({
                firmasahibi: '',
                tanitim: [],
                tanitim1: '',
                title: '',
            });
            setFiles([]); // Dosya seçim alanını temizle
            setPreviews([]); // Önizleme alanını temizle
            setProgress({}); // Yükleme ilerlemesini temizle

        } catch (err) {
            console.log(err);
            toast.error("Bir hata oluştu, lütfen tekrar deneyin.");
        }
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="p-6 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-6">New Project</h1>
                    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
                        <form onSubmit={handleAdd}>
                            <div className="mb-4">
                                <label htmlFor="files" className="block text-gray-700 font-medium mb-2">
                                    Images
                                    <DriveFolderUploadOutlinedIcon className="ml-2 text-gray-500" />
                                </label>
                                <input
                                    type="file"
                                    id="files"
                                    multiple
                                    onChange={handleFilesChange}
                                    className="hidden"
                                />
                                <label htmlFor="files" className="cursor-pointer p-2 bg-blue-500 text-white rounded-md inline-block">Upload Images</label>
                                <div className="flex flex-wrap mt-2 gap-2">
                                    {previews.map((preview, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="w-24 h-24 object-cover border border-gray-300 rounded-lg"
                                            />
                                            <div className="absolute bottom-0 left-0 w-full bg-gray-700 bg-opacity-50 text-white text-xs text-center rounded-b-lg">
                                                {progress[files[index].name] ? `${Math.round(progress[files[index].name])}%` : '0%'}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="description">Firma Sahibi</label>
                                <input
                                    id="firmasahibi"
                                    placeholder="Enter firma sahibi"
                                    onChange={handleInput}
                                    value={data.firmasahibi}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="year">Tanıtım</label>
                                <textarea
                                    id="tanitim"
                                    type="text"
                                    placeholder="Tanıtım virgül boşluk olarak ayırarak yazınız..."
                                    onChange={handleInput}
                                    value={data.tanitim}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="sehir">Tanıtım 1</label>
                                <input
                                    id="tanitim1"
                                    type="text"
                                    placeholder="Enter Tanıtım 1"
                                    onChange={handleInput}
                                    value={data.tanitim1}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2" htmlFor="sehir">Title</label>
                                <input
                                    id="title"
                                    type="text"
                                    placeholder="Enter Title"
                                    onChange={handleInput}
                                    value={data.title}
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                                >
                                    Add Project
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewHakkinda;
