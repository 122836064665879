import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { auth } from '../../utils/firebase';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./dashboard.scss";
import Widget from "../../components/widget/Widget";
import Table from "../../components/table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../utils/status';
import { fetchProjects } from './../../redux/projectSlice';
import { fetchUsers } from './../../redux/usersSlice';
import Users from '../../components/table/Users';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { projectdata, projecterror, projectstatus } = useSelector((state) => state.project);
    const { usersdata, userserror, usersstatus } = useSelector((state) => state.users);

    useEffect(() => {
        if (projectstatus === STATUS.IDLE) {
            dispatch(fetchProjects());
        }
        if (usersstatus === STATUS.IDLE) {
            dispatch(fetchUsers());
        }
    }, [projectstatus, usersstatus, dispatch]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="widgets">
                    <Widget type="user" />
                    <Widget type="slider" />
                    <Widget type="projeler" />
                    <Widget type="referanslar" />
                </div>
                <div className="listContainer">
                    <Table title={"Projeler"} data={projectdata} error={projecterror} status={projectstatus} />
                    <Users title={"Kullanıcılar"} data={usersdata} error={userserror} status={usersstatus} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
