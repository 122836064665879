import { Splide, SplideSlide } from "@splidejs/react-splide";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const ImageSlider = ({ data, title }) => {

    return (
        <div className="mx-auto justify-center items-center pb-2">
            <div className="text-xl sm:text-2xl md:text-3xl font-semibold ml-4 mt-4 mb-[-30px]">
                {title}
            </div>
            <Splide
                options={{
                    perPage: 1, // Default perPage value for mobile
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                        768: {
                            perPage: 2,
                        },
                        1024: {
                            perPage: 3,
                        },
                        1440: {
                            perPage: 4,
                        },
                        2560: {
                            perPage: 5,
                        },
                        2800: {
                            perPage: 6,
                        },
                    },
                    rewind: true,
                    gap: "1rem",
                }}
                aria-label="Projeler"
            >
                {!data && (
                    <div className="h-[200px] flex items-center">
                        <Loader />
                    </div>
                )}
                {data?.map((data) => (
                    <SplideSlide key={data.id} className="relative">
                        <Link to={`projects/${data.id}`} className="block">
                            <img
                                src={data.images[0].url}
                                alt={data.title}
                                className="w-full h-[45vh] md:h-[40vh] lg:h-[35vh] object-cover rounded"
                            />
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-center text-xs md:text-sm lg:text-base">
                                {data.title.length > 35
                                    ? data.title.slice(0, 35) + "..."
                                    : data.title}
                            </div>
                        </Link>
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
};

export default ImageSlider;
