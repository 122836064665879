import { useState } from 'react';
import { navItems } from './../static/data';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header = ({ active }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const { user } = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const { themes } = useSelector((state) => state.theme);
    const defaultThemesFromState = themes.filter(theme => theme.isDefault);
    const { background = '#111827', button = '#55acee', text = '#ffffff' } = defaultThemesFromState.length > 0 ? defaultThemesFromState[0] : {};

    const handleAdminClick = () => {
        navigate('/dashboard');
    };

    const handleSubMenuToggle = () => {
        setSubMenuOpen(prevSubMenuOpen => !prevSubMenuOpen);
    };

    const closeMobileMenu = () => {
        setMenuOpen(false);
        setSubMenuOpen(false);
    };

    return (
        <div className="text-white" style={{ backgroundColor: background, color: text }}>
            <div className="flex items-center px-1 py-1">
                <div className="flex items-center">
                    <Link
                        to="/"
                        className={`justify-start mx-1 my-1 ${active ? 'text-[#17dd1f]' : 'text-white'} font-semibold`}
                    >
                        <img
                            src="https://res.cloudinary.com/dafvzct6j/image/upload/v1714995470/gurgenassets/z8kgtu65rwqykwqlsrvq.svg"
                            className="justify-start w-54 h-auto max-w-full max-h-20"
                            alt=""
                        />
                    </Link>
                    <div className="block justify-end items-center lg:hidden">
                        <button
                            onClick={() => setMenuOpen(!menuOpen)}
                            className="text-white hover:text-[#17dd1f]"
                            aria-label="Menu"
                        >
                            <svg
                                className="w-10 h-10 justify-end items-center"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="hidden lg:flex lg:text-[16px] xl:text-[18px] 2xl:text-xl 3xl:text-3xl items-center justify-center">
                    {navItems.map((item, index) => (
                        <div key={index} className="relative menu">
                            {item.title === 'Projelerimiz' ? (
                                <div>
                                    <button
                                        onClick={handleSubMenuToggle}
                                        className={`px-4 py-3 ${active === index + 1 ? 'text-[#17dd1f]' : 'text-white'} font-semibold`}
                                    >
                                        {item.title}
                                    </button>
                                    {subMenuOpen && (
                                        <div className="absolute mt-1 w-48 shadow-lg rounded z-50" style={{ backgroundColor: background }}>
                                            <Link
                                                to="/projects?filter=devameden"
                                                className="block px-4 py-2 hover:bg-[#17dd1f]"
                                                style={{ color: text }}
                                                onClick={closeMobileMenu}
                                            >
                                                Devam Eden Projelerimiz
                                            </Link>
                                            <Link
                                                to="/projects?filter=tamamlanan"
                                                className="block px-4 py-2 hover:bg-[#17dd1f]"
                                                style={{ color: text }}
                                                onClick={closeMobileMenu}
                                            >
                                                Tamamlanan Projelerimiz
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Link
                                    to={item.url}
                                    className={`px-4 py-3 ${active === index + 1 ? 'text-[#17dd1f]' : 'text-white'} font-semibold`}
                                    style={{ color: text }}
                                >
                                    {item.title}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
                {user && (
                    <button
                        onClick={handleAdminClick}
                        className="hidden lg:flex lg:ml-auto lg:mr-4 px-4 py-2 font-semibold rounded transition duration-200"
                        style={{ backgroundColor: button, color: text }}
                    >
                        Admin
                    </button>
                )}
            </div>
            {menuOpen && (
                <div className="lg:hidden">
                    {navItems.map((item, index) => (
                        <div key={index} className="mb-4">
                            {item.title === 'Projelerimiz' ? (
                                <>
                                    <button
                                        onClick={handleSubMenuToggle}
                                        className={`block px-4 py-3 ${active === index + 1 ? 'text-[#17dd1f]' : 'text-white'} font-semibold`}
                                        style={{ color: text }}
                                    >
                                        {item.title}
                                    </button>
                                    {subMenuOpen && (
                                        <div className="mt-2">
                                            <Link
                                                to="/projects?filter=devameden"
                                                className="block px-4 py-2 hover:bg-[#17dd1f] rounded"
                                                style={{ color: text, backgroundColor: background }}
                                                onClick={closeMobileMenu}
                                            >
                                                Devam Eden Projelerimiz
                                            </Link>
                                            <Link
                                                to="/projects?filter=tamamlanan"
                                                className="block px-4 py-2 hover:bg-[#17dd1f] rounded"
                                                style={{ color: text, backgroundColor: background }}
                                                onClick={closeMobileMenu}
                                            >
                                                Tamamlanan Projelerimiz
                                            </Link>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Link
                                    to={item.url}
                                    className={`block px-4 py-3 ${active === index + 1 ? 'text-[#17dd1f]' : 'text-white'} font-semibold`}
                                    style={{ color: text }}
                                    onClick={closeMobileMenu}
                                >
                                    {item.title}
                                </Link>
                            )}
                        </div>
                    ))}
                    {user && (
                        <button
                            onClick={handleAdminClick}
                            className="block w-full text-left px-4 py-2 text-lg font-semibold rounded transition duration-200 mt-4"
                            style={{ backgroundColor: button, color: text }}
                        >
                            Admin
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Header;
