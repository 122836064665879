import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

const storage = getStorage();

export const uploadImage = (file, onProgress) => {
    return new Promise((resolve, reject) => {
        // Yeni bir dosya adı oluştur
        const newFileName = uuidv4() + '_' + file.name.replace(/\s+/g, '-'); // Benzersiz bir dosya adı oluştur

        // Yeni dosyanın referansını oluştur
        const storageRef = ref(storage, `images/${newFileName}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) {
                    onProgress(progress);
                }
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                reject(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                resolve({ newFileName, downloadURL }); // Dosya adını ve indirme URL'sini resolve ediyoruz
            }
        );
    });
};
