import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteSlider, fetchSlider, updateSlider } from '../../redux/sliderSlice';
import { STATUS } from '../../utils/status';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const SliderList = ({ title }) => {
    const dispatch = useDispatch();
    const [editingSlider, setEditingSlider] = useState(null);
    const [editedSlider, setEditedSlider] = useState({});

    const { sliderdata, sliderstatus, slidererror } = useSelector((state) => state.slider);

    useEffect(() => {
        if (sliderstatus === STATUS.IDLE) {
            dispatch(fetchSlider());
        }
    }, [sliderstatus, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteSlider(id));
    };

    const handleUpdate = (id) => {
        dispatch(updateSlider({ id, updatedData: editedSlider }));
        setEditingSlider(null);
        setEditedSlider({});
    };

    const handleEdit = (user) => {
        setEditingSlider(user.id);
        setEditedSlider(user);
    };

    const handleCancel = () => {
        setEditingSlider(null);
        setEditedSlider({});
    };
    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-slider" className="mb-2 inline-block">
                <button className="btn btn-primary">New Slider</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Image</TableCell>
                            <TableCell className="tableCell">Title</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sliderstatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {sliderstatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Error: {slidererror}</TableCell>
                            </TableRow>
                        )}
                        {sliderstatus === STATUS.SUCCESS && sliderdata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingSlider === row.id ? (
                                        <input
                                            type="text"
                                            value={editedSlider.images[0]}
                                            onChange={(e) => setEditedSlider({ ...editedSlider, displayName: e.target.value })}
                                            placeholder="Edit User Name"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        <div className="flex items-center">
                                            <img src={row?.images[0]} alt="" className="w-10 h-10 rounded-full mr-1 object-cover" />
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingSlider === row.id ? (
                                        <input
                                            type="text"
                                            value={editedSlider.title}
                                            onChange={(e) => setEditedSlider({ ...editedSlider, title: e.target.value })}
                                            placeholder="Edit Title"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.title
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingSlider === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SliderList
