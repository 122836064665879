import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { STATUS } from "../../utils/status";
import { deleteUsers, fetchUsers, updateUsers } from "../../redux/usersSlice";
import { serverTimestamp } from "firebase/firestore";

const Users = ({ title }) => {
    const dispatch = useDispatch();
    const [editingUser, setEditingUser] = useState(null);
    const [editedUser, setEditedUser] = useState({});

    const { usersdata, usersstatus, userserror } = useSelector((state) => state.users);

    useEffect(() => {
        if (usersstatus === STATUS.IDLE) {
            dispatch(fetchUsers());
        }
    }, [usersstatus, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteUsers(id));
    };

    const handleUpdate = (id) => {
        dispatch(updateUsers({ id, updatedData: editedUser, timeStamp: serverTimestamp() }));
        setEditingUser(null);
        setEditedUser({});
    };

    const handleEdit = (user) => {
        setEditingUser(user.id);
        setEditedUser(user);
    };

    const handleCancel = () => {
        setEditingUser(null);
        setEditedUser({});
    };
    return (
        <>
            <div className="listTitle">{title}</div>
            <Link to="/new-user" className="mb-2 inline-block">
                <button className="btn btn-primary">New User</button>
            </Link>
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableCell">ID</TableCell>
                            <TableCell className="tableCell">Name</TableCell>
                            <TableCell className="tableCell">Meslek</TableCell>
                            <TableCell className="tableCell">İş Tercübesi</TableCell>
                            <TableCell className="tableCell">Telefon</TableCell>
                            <TableCell className="tableCell">Yaş</TableCell>
                            <TableCell className="tableCell">Şehir</TableCell>
                            <TableCell className="tableCell">Mail</TableCell>
                            <TableCell className="tableCell">Adress</TableCell>
                            <TableCell className="tableCell">Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersstatus === STATUS.LOADING && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Loading...</TableCell>
                            </TableRow>
                        )}
                        {usersstatus === STATUS.FAIL && (
                            <TableRow>
                                <TableCell colSpan={9} className="tableCell">Error: {userserror}</TableCell>
                            </TableRow>
                        )}
                        {usersstatus === STATUS.SUCCESS && usersdata.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell className="tableCell">{row.id}</TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.displayName}
                                            onChange={(e) => setEditedUser({ ...editedUser, displayName: e.target.value })}
                                            placeholder="Edit User Name"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        <div className="flex items-center">
                                            <img src={row?.images[0].url} alt="" className="w-8 h-8 rounded-full mr-2 object-cover" />
                                            {row.displayName}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.job}
                                            onChange={(e) => setEditedUser({ ...editedUser, job: e.target.value })}
                                            placeholder="Edit Job"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.job
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.jobyear}
                                            onChange={(e) => setEditedUser({ ...editedUser, jobyear: e.target.value })}
                                            placeholder="Edit Job Year"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.jobyear
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.phone}
                                            onChange={(e) => setEditedUser({ ...editedUser, phone: e.target.value })}
                                            placeholder="Edit Phone"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.phone
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.age}
                                            onChange={(e) => setEditedUser({ ...editedUser, age: e.target.value })}
                                            placeholder="Edit Age"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.age
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.country}
                                            onChange={(e) => setEditedUser({ ...editedUser, country: e.target.value })}
                                            placeholder="Edit Country"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.country
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.email}
                                            onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                                            placeholder="Edit Email"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.email
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    {editingUser === row.id ? (
                                        <input
                                            type="text"
                                            value={editedUser.address}
                                            onChange={(e) => setEditedUser({ ...editedUser, address: e.target.value })}
                                            placeholder="Edit Address"
                                            className="border p-1 rounded"
                                        />
                                    ) : (
                                        row.address
                                    )}
                                </TableCell>
                                <TableCell className="tableCell">
                                    <div className="flex items-center gap-3">
                                        <div
                                            className="deleteButton p-1 border border-dotted border-red-600 text-red-600 cursor-pointer rounded"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </div>
                                        {editingUser === row.id ? (
                                            <>
                                                <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleUpdate(row.id)}>Save</div>
                                                <div className="viewButton p-1 border border-dotted border-pink-600 text-pink-600 cursor-pointer rounded" onClick={handleCancel}>Cancel</div>
                                            </>
                                        ) : (
                                            <div className="viewButton p-1 border border-dotted border-blue-600 text-blue-600 cursor-pointer rounded" onClick={() => handleEdit(row)}>Edit</div>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Users;
