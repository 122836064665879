import React from 'react';
import svg from "../assets/404.svg";

const PageNotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <img className="max-w-full h-auto" src={svg} alt="404 Not Found" />
            <button
                className="mt-8 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                onClick={() => window.location.href = '/'}
            >
                Back to Home
            </button>
        </div>
    );
}

export default PageNotFound;