import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../utils/status';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const convertTimestampToDate = (project) => {
    return {
        ...project,
        timeStamp: project.timeStamp && project.timeStamp.toDate ? project.timeStamp.toDate().toISOString() : null,
    };
};


const initialState = {
    aboutdata: [],
    aboutstatus: STATUS.IDLE,
    abouterror: null,
};

export const fetchAbout = createAsyncThunk('about/fetchAbout', async () => {
    const querySnapshot = await getDocs(collection(db, 'about'));
    let about = [];
    querySnapshot.forEach((doc) => {
        about.push({ id: doc.id, ...convertTimestampToDate(doc.data()) });
    });
    return about;
});

// Yeni bir proje oluşturmak için bir async thunk
export const addAbout = createAsyncThunk('projects/addAbout', async ({ newProject }) => {
    const projectWithImages = { ...newProject, timeStamp: serverTimestamp() };
    const docRef = await addDoc(collection(db, "about"), projectWithImages);
    return { id: docRef.id, ...projectWithImages };
});

// Proje silmek için bir async thunk
export const deleteAbout = createAsyncThunk('projects/deleteAbout', async (id) => {
    await deleteDoc(doc(db, "about", id));
    return id;
});

// Proje güncellemek için bir async thunk
export const updateAbout = createAsyncThunk('projects/updateAbout', async ({ id, updatedData }) => {
    const projectDoc = doc(db, "about", id);
    const projectWithImages = { ...updatedData, timeStamp: serverTimestamp() };
    await updateDoc(projectDoc, projectWithImages);
    return { id, updatedData: projectWithImages };
});


const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAbout.pending, (state) => {
                state.aboutstatus = STATUS.LOADING;
            })
            .addCase(fetchAbout.fulfilled, (state, action) => {
                state.aboutstatus = STATUS.SUCCESS;
                state.aboutdata = action.payload;
            })
            .addCase(fetchAbout.rejected, (state, action) => {
                state.aboutstatus = STATUS.FAIL;
                state.abouterror = action.error.message;
            })
            .addCase(addAbout.pending, (state) => {
                state.aboutstatus = STATUS.LOADING;
            })
            .addCase(addAbout.fulfilled, (state, action) => {
                state.aboutstatus = STATUS.SUCCESS;
                state.aboutdata.push(action.payload);
            })
            .addCase(addAbout.rejected, (state, action) => {
                state.aboutstatus = STATUS.FAIL;
                state.abouterror = action.error.message;
            })
            .addCase(deleteAbout.pending, (state) => {
                state.aboutstatus = STATUS.LOADING;
            })
            .addCase(deleteAbout.fulfilled, (state, action) => {
                state.aboutstatus = STATUS.SUCCESS;
                state.aboutdata = state.aboutdata.filter(project => project.id !== action.payload);
            })
            .addCase(deleteAbout.rejected, (state, action) => {
                state.aboutstatus = STATUS.FAIL;
                state.abouterror = action.error.message;
            })
            .addCase(updateAbout.pending, (state) => {
                state.aboutstatus = STATUS.LOADING;
            })
            .addCase(updateAbout.fulfilled, (state, action) => {
                state.aboutstatus = STATUS.SUCCESS;
                const { id, updatedData } = action.payload;
                const existingProjectIndex = state.aboutdata.findIndex(project => project.id === id);
                if (existingProjectIndex !== -1) {
                    state.aboutdata[existingProjectIndex] = {
                        ...state.aboutdata[existingProjectIndex],
                        ...convertTimestampToDate(updatedData)
                    };
                }
            })
            .addCase(updateAbout.rejected, (state, action) => {
                state.aboutstatus = STATUS.FAIL;
                state.abouterror = action.error.message;
            });
    },
});

export default aboutSlice.reducer;
