import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';

const AdminProfile = () => {
    const dispatch = useDispatch();
    const { user, status } = useSelector((state) => state.admin); // state.admin'dan user'ı alıyoruz  

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <div className="flex flex-col items-center p-6 bg-gray-100 rounded-2xl shadow-md max-w-xl mx-auto mt-10 mb-5">
                    <img
                        src={user.photoURL}
                        alt="Profile"
                        className="w-34 h-34 rounded-full mb-4 shadow-lg"
                    />
                    <h1 className="text-2xl font-bold mb-2">{user.displayName}</h1>
                    <p className="text-gray-700 mb-1">{user.email}</p>
                    <p className='text-gray-500 mb-4'>{user.creationTime}</p>
                </div>
            </div>
        </div>
    )
}

export default AdminProfile;
