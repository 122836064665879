// src/features/userSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { STATUS } from '../utils/status';
import { auth, provider } from '../utils/firebase';
import { toast } from 'react-toastify';

const initialState = {
    user: null,
    status: STATUS.IDLE,
    error: null,
};

export const loginUser = createAsyncThunk('admin/loginUser', async ({ email, password }, { rejectWithValue }) => {
    const auth = getAuth();
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // Serileştirilebilir kullanıcı nesnesi oluşturma
        const user = userCredential.user;
        // Serileştirilebilir kullanıcı nesnesi oluşturma
        const serializableUser = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            creationTime: user.metadata.creationTime
        };
        toast.success("Kullanıcı başarıyla giriş yaptı");
        return serializableUser;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const loginUserWithGoogle = createAsyncThunk(
    'admin/loginUserWithGoogle',
    async (_, { rejectWithValue }) => {
        try {
            const userCredential = await signInWithPopup(auth, provider);
            // Serileştirilebilir kullanıcı nesnesi oluşturma
            const user = userCredential.user;
            // Serileştirilebilir kullanıcı nesnesi oluşturma
            const serializableUser = {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                creationTime: user.metadata.creationTime
            };
            toast.success("Kullanıcı başarıyla giriş yaptı");

            return serializableUser;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const logout = createAsyncThunk('admin/logout', async () => {
    const auth = getAuth();
    await signOut(auth);
    toast.error("Kullanıcı oturumu kapattı, giriş sayfasına yönlendiriliyorsunuz...");
});

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = STATUS.LOADING;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = STATUS.SUCCESS;
                state.user = action.payload;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.payload;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.status = STATUS.IDLE;
            })
            .addCase(loginUserWithGoogle.pending, (state) => {
                state.status = STATUS.LOADING;
                state.error = null;
            })
            .addCase(loginUserWithGoogle.fulfilled, (state, action) => {
                state.status = STATUS.SUCCESS;
                state.user = action.payload;
            })
            .addCase(loginUserWithGoogle.rejected, (state, action) => {
                state.status = STATUS.FAIL;
                state.error = action.payload;
            });
    },
});

export default adminSlice.reducer;
